import { defineStore } from 'pinia'
import { useUXUIStore } from '../uxui'
import { useApiCall } from '@/composables/useApiCall'
import {
  getTariffListApiCall,
  getUserTariffsApiCall,
  getTariffItemApiCall,
  getApplicationDataApiCall,
  changeTariffItemStatusApiCall,
  deleteTariffItemApiCall,
} from '@/api/tariff'
import { Tariff } from '@/types/user'
import { RootState, RootGetters, RootActions } from './types'
import {
  TariffListGetPayload,
  TariffListGetResponse,
  TariffItemStatusChangePayload,
  TariffItemStatusChangeResponse,
  TariffItemGetDeletePayload,
  TariffItemGetResponse,
  TariffItemDeleteResponse,
  AppStatResponse,
} from '@/types/tariff'
import { DefaultError } from '@/types/httpError'

export const useTariffsStore = defineStore<
  string,
  RootState,
  RootGetters,
  RootActions
>('tariff', {
  state() {
    return {
      allTariffs: null,
      selectTariffs: null,
      searchParams: null,
      currentPage: 1,
      lastPage: 1,
      appStat: null,
      selectedTariff: null,
      isPanelOpen: false,
      error: null,
    }
  },
  actions: {
    resetSearchParams() {
      this.searchParams = { page: 1 }
    },
    async getAllTariffs(
      params?: Partial<TariffListGetPayload>,
      isLazy?: boolean,
    ) {
      const {
        data: tariffListData,
        executeApiCall: getTariffList,
        error: tariffListError,
      } = useApiCall<
        TariffListGetResponse,
        DefaultError,
        Partial<TariffListGetPayload>
      >(getTariffListApiCall, true)

      this.searchParams = {
        ...this.searchParams,
        ...params,
        itemsPerPage: 40,
      }

      try {
        await getTariffList(this.searchParams)
        if (tariffListData.value) {
          if (params && 'isPaginated' in params) {
            this.selectTariffs = tariffListData.value.data
            return
          }
          const { currentPage, lastPage } = tariffListData.value.meta
          this.currentPage = currentPage
          this.lastPage = lastPage
          if (isLazy) {
            if (this.currentPage === 1) {
              this.allTariffs = tariffListData.value.data
              return
            }
            if (Array.isArray(this.allTariffs)) {
              this.allTariffs = [
                ...this.allTariffs,
                ...tariffListData.value.data,
              ]
            }
          } else {
            this.allTariffs = tariffListData.value.data
          }
        }
      } catch (error) {
        if (tariffListError.value?.data.error) {
          this.error = tariffListError.value?.data.error
        }
      }
    },
    async loadMoreTariffs() {
      if (this.currentPage < this.lastPage) {
        this.currentPage++
        let newParams = { page: this.currentPage }
        if (this.searchParams) {
          newParams = { ...this.searchParams, ...newParams }
        }
        await this.getAllTariffs(newParams, true)
      }
    },
    async getUserTariffs() {
      const {
        data: userTariffsData,
        executeApiCall: getUserTariffs,
        error: userTariffsErr,
      } = useApiCall<TariffListGetResponse, DefaultError>(
        getUserTariffsApiCall,
        true,
      )
      try {
        await getUserTariffs()
        if (userTariffsData.value) {
          this.allTariffs = userTariffsData.value.data
        }
      } catch (error) {
        if (userTariffsErr.value?.data.error) {
          this.error = userTariffsErr.value?.data.error
        }
      }
    },
    async getTariff(params: TariffItemGetDeletePayload) {
      const {
        data: tariffItemData,
        executeApiCall: getTariffItem,
        error: tariffItemError,
      } = useApiCall<
        TariffItemGetResponse,
        DefaultError['error'],
        TariffItemGetDeletePayload
      >(getTariffItemApiCall, true)

      try {
        await getTariffItem(params)
      } catch (error) {
        if (tariffItemError.value?.data) {
          this.error = tariffItemError.value?.data
        }
      }

      return tariffItemData.value ? tariffItemData.value.data : null
    },
    async changeTariffStatus(params: TariffItemStatusChangePayload) {
      const {
        data,
        executeApiCall,
        error: message,
      } = useApiCall<
        TariffItemStatusChangeResponse,
        DefaultError,
        TariffItemStatusChangePayload
      >(changeTariffItemStatusApiCall, true)

      try {
        await executeApiCall(params)
        if (data.value?.success === 1) {
          const tariff = await this.getTariff(params)
          if (tariff) {
            this.replaceTariff(params.id, tariff)
          }
        }
      } catch (error) {
        if (message.value?.data.error) {
          this.error = message.value?.data.error
        }
      }
    },
    async deleteTariff(params: TariffItemGetDeletePayload) {
      const uxuiStore = useUXUIStore()
      const {
        data: deleteTariffResponse,
        executeApiCall: deleteTariffAction,
        error: deleteTariffError,
      } = useApiCall<
        TariffItemDeleteResponse,
        DefaultError['error'],
        TariffItemGetDeletePayload
      >(deleteTariffItemApiCall, true)

      try {
        await deleteTariffAction(params)
        if (deleteTariffResponse.value?.success === 1) {
          this.replaceTariff(params.id, null)
          uxuiStore.openNotification('done', `${params.label} успешно удален`)
        }
      } catch (error) {
        if (deleteTariffError.value?.data) {
          this.error = deleteTariffError.value?.data

          if (deleteTariffError.value?.data.message) {
            uxuiStore.openNotification(
              'error',
              deleteTariffError.value?.data.message,
            )
          }
        }
      }
    },
    async getAppStat() {
      const {
        data: appStatData,
        executeApiCall: getAppStat,
        error: appStatError,
      } = useApiCall<AppStatResponse, DefaultError>(
        getApplicationDataApiCall,
        true,
      )
      try {
        await getAppStat()
        if (appStatData.value) {
          this.appStat = appStatData.value.data
        }
      } catch (error) {
        if (appStatError.value?.data.error) {
          this.error = appStatError.value?.data.error
        }
      }
    },
    replaceTariff(id: number, newBody: Tariff | null) {
      if (this.allTariffs) {
        if (newBody) {
          const index = this.allTariffs?.findIndex((item) => item.id === id)
          if (index !== undefined) {
            this.allTariffs[index] = {
              ...this.allTariffs[index],
              ...newBody,
            }
          }
          return
        }
        this.allTariffs = this.allTariffs?.filter((item) => item.id !== id)
      }
    },
    openPanel() {
      this.isPanelOpen = true
    },
    closePanel() {
      this.isPanelOpen = false
    },
  },
})
