export const modalsTitleNames: string[] = [
  '',
  'Формирование акта',
  'Органы рассмотрения',
  'Редактирование дела',
  'Редактирование органа рассмотрения',
  'Создание клиента',
  'Редактирование задачи',
  'Создание транзакции',
  'Создание дела',
  'Создание органа рассмотрения',
  'Создание быстрой заметки',
  'Редактирование быстрой заметки',
  'Создание задачи',
  'Создать тариф',
  'Создание события',
  'Редактирование события',
  'Ссылка на оплату тарифа',
  'Редактирование заметки',
  'Создание заметки',
]
