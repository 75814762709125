const PHONE_REG_EXP =
  /(\+7|7|8)[\s-]?\(?\d{3}\)?[\s-]?\d{3}[\s-]?\d{2}[\s-]?\d{2}/
const URL_REG_EXP = /(https?:\/\/[^\s]+)/
const EMAIL_REG_EXP =
  /\b\w+([-+.']\w+)*@\w+([-.]\w+)*\.(com|net|org|ru|info|io|dev)\b/
const combinedRegExp = new RegExp(
  `${PHONE_REG_EXP.source}|${URL_REG_EXP.source}|${EMAIL_REG_EXP.source}`,
  'gi',
)

export default (text: string) => {
  return text.replace(combinedRegExp, (match) => {
    if (URL_REG_EXP.test(match)) {
      return `<a href="${match}" target="_blank">${match}</a>`
    } else if (PHONE_REG_EXP.test(match)) {
      return `<a href="tel:${match.replace(/\D/g, '')}">${match}</a>`
    } else if (EMAIL_REG_EXP.test(match)) {
      return `<a href="mailto:${match}">${match}</a>`
    }
    return match
  })
}
