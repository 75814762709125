import axios from 'axios'
import {
  UserData,
  UserDataApiResponse,
  WorkShedule,
  UserPasswordApiPayload,
  UserTariffChangePayload,
  UserSubscriptionSuccessResponse,
  PaymentLinkResponse,
  ActiveSessionsResponse,
} from '@/types/user'
import type { TariffItemDeleteResponse } from '@/types/tariff'

const userDataInfoApiCall = async (
  abortController?: AbortController,
  params?: Partial<UserData>,
): Promise<UserDataApiResponse> => {
  const response = await axios.get('/v1/me', {
    params: { ...params },
    signal: abortController?.signal,
  })
  return response.data
}

const workShedulePartialChangeApiCall = async (
  abortController?: AbortController,
  params?: Partial<WorkShedule>,
): Promise<UserDataApiResponse> => {
  const response = await axios.patch(
    'v1/user/work-day',
    { ...params },
    {
      signal: abortController?.signal,
    },
  )
  return response.data
}

const userChangeApiCall = async (
  abortController?: AbortController,
  params?: Partial<UserData>,
): Promise<UserDataApiResponse> => {
  const response = await axios.put(
    'v1/user',
    { ...params },
    {
      signal: abortController?.signal,
    },
  )
  return response.data
}

const deleteUserApiCall = async (
  abortController?: AbortController,
): Promise<UserDataApiResponse> => {
  const response = await axios.delete('/v1/user', {
    signal: abortController?.signal,
  })
  return response.data
}

const changePasswordApiCall = async (
  abortController?: AbortController,
  params?: Partial<UserPasswordApiPayload>,
): Promise<UserDataApiResponse> => {
  const response = await axios.put(
    'v1/user/update-password',
    { ...params },
    {
      signal: abortController?.signal,
    },
  )
  return response.data
}

const getUserSubscriptionApiCall = async (
  abortController?: AbortController,
): Promise<UserSubscriptionSuccessResponse> => {
  const { data } = await axios.get('/v1/subscription', {
    signal: abortController?.signal,
  })
  return data
}

const changeUserTariffApiCall = async (
  abortController?: AbortController,
  params?: UserTariffChangePayload,
) => {
  const { data } = await axios.put(
    '/v1/subscription',
    {
      ...params,
    },
    { signal: abortController?.signal },
  )
  return data
}

const getTariffPaymentLinkApiCall = async (
  abortController?: AbortController,
  params?: UserTariffChangePayload,
): Promise<PaymentLinkResponse> => {
  const { data } = await axios.get(
    `/v1/subscription/${params?.id}/payment-link`,
    {
      signal: abortController?.signal,
    },
  )
  return data
}

const cancelSubscriptionApiCall = async (abortController?: AbortController) => {
  const { data } = await axios.delete('/v1/subscription', {
    signal: abortController?.signal,
  })
  return data
}

const getActiveSessionsApiCall = async (
  abortController?: AbortController,
): Promise<ActiveSessionsResponse> => {
  const { data } = await axios.get('/v1/active-token', {
    signal: abortController?.signal,
  })
  return data.data
}

const deleteActiveSessionApiCall = async (
  abortController?: AbortController,
  params?: { id: number },
): Promise<TariffItemDeleteResponse> => {
  const { data } = await axios.delete(`/v1/active-token/${params?.id}`, {
    signal: abortController?.signal,
  })
  return data.data
}

export {
  userDataInfoApiCall,
  workShedulePartialChangeApiCall,
  userChangeApiCall,
  deleteUserApiCall,
  changePasswordApiCall,
  getUserSubscriptionApiCall,
  changeUserTariffApiCall,
  getTariffPaymentLinkApiCall,
  cancelSubscriptionApiCall,
  getActiveSessionsApiCall,
  deleteActiveSessionApiCall,
}
