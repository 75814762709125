<template>
  <div
    :class="[
      'modal__header',
      {
        config__header: ['FormationAct', 'CopyLinkModal'].includes(
          uxuiStore.modalName.modalName,
        ),
      },
    ]"
  >
    <h2 class="modal__title" v-if="index">{{ modalsTitleNames[index] }}</h2>
    <button class="modal__close-button" @click="toggleSave">
      <SvgIcon icon="x" class="modal__button-icon" />
    </button>
    <div v-if="isSave" class="modal__close-modal">
      <div class="modal__close-modal-content">
        <span class="modal__close-modal-title"
          >Хотите ли вы сохранить изменения?</span
        >
        <div v-if="!isValid" class="modal__close-modal-subtitle">
          Для сохранения необходимо ввести все обязательные поля
        </div>
        <div class="modal__close-modal-wrapper">
          <button @click="setSaveData" class="modal__close-modal-success">
            Да
          </button>
          <button @click="closeSaveData" class="modal__close-modal-close">
            Нет
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts" src="./ModalHeader.ts"></script>
<style lang="scss" scoped src="./ModalHeader.scss"></style>
