import {
  defineComponent,
  ref,
  reactive,
  computed,
  watch,
  onBeforeMount,
  onBeforeUnmount,
} from 'vue'
import { useRouter } from 'vue-router'
import { useFinanceStore } from '@/store/finance'
import { useClientsStore } from '@/store/client'
import { useLawsuitStore } from '@/store/lawsuite'
import { useMainStore } from '@/store/main'
import { useApiCall } from '@/composables/useApiCall'
import {
  createTransactionApiCall,
  editTransactionApiCall,
} from '@/api/transaction'
import {
  checkDateValidity,
  getTimezoneDate,
  getFormatDateWithDash,
} from '@/helpers/dateFormatter'
import {
  TransactionItemCreatePayload,
  TransactionItemEditPayload,
  TransactionType,
  TransactionSuccessResponse,
} from '@/types/transaction'
import { DefaultError } from '@/types/httpError'
import { useSavePopupStore } from '@/store/savePopup'

export default defineComponent({
  name: 'TransactionForm',
  props: {
    oneColumn: Boolean,
  },
  setup() {
    type ValidationErrorsKeys = Partial<typeof fields>

    const fields = reactive({
      date: '',
      time: '',
      type: 'credit',
      amount: '',
      customer: '',
      lawsuit: '',
      comment: '',
    })
    const isTransactionNew = ref(true)
    const transactionId = ref<number | null>(null)
    const selectedCustomerId = ref('')
    const validationErrors = ref<
      { [K in keyof ValidationErrorsKeys]?: string[] } | null
    >(null)
    const validation = computed(() => ({
      time:
        fields.time.length === 5 &&
        !/^([01]?[0-9]|2[0-3]):[0-5][0-9]$/.test(fields.time),
    }))

    const financeStore = useFinanceStore()
    const clientsStore = useClientsStore()
    const lawsuitStore = useLawsuitStore()
    const mainStore = useMainStore()
    const savePopupStore = useSavePopupStore()
    const router = useRouter()

    const isTabletOrMobile = computed(() => ({
      tablet: mainStore.isTablet,
      mobile: mainStore.isMobile,
    }))
    const selectClientOptions = computed(() => clientsStore.notPaginatedClients)
    const selectLawsuitOptions = computed(() => {
      if (selectedCustomerId.value && lawsuitStore.lawsuitData) {
        return lawsuitStore.lawsuitData?.filter(
          (item) => String(item.customer?.id) === selectedCustomerId.value,
        )
      }
      return lawsuitStore.lawsuitData ?? []
    })
    const selectInitialValues = computed(() => {
      const client = selectClientOptions.value?.find(
        (client) => client.id === Number(fields.customer),
      )
      const lawsuit = selectLawsuitOptions.value?.find(
        (lawsuit) => lawsuit.id === Number(fields.lawsuit),
      )
      return { client, lawsuit }
    })
    const isSubmitBtnDisabled = computed(
      () =>
        !fields.time ||
        !fields.amount ||
        !checkDateValidity(fields.date) ||
        isNewTransactionLoading.value ||
        isEditedTransactionLoading.value,
    )

    const TYPES = [
      { text: 'Расход', value: 'credit' },
      { text: 'Доход', value: 'debit' },
    ]

    watch(
      () => validation.value,
      (nevVal) => {
        if (!nevVal.time) {
          delete validationErrors.value?.time
          return
        }
        if (validationErrors.value) {
          validationErrors.value.time = ['Некорректное время']
        } else {
          validationErrors.value = { time: ['Некорректное время'] }
        }
      },
    )

    const onValueChange = (objKey: string, value: any) => {
      if (typeof value === 'number') {
        if (objKey === 'lawsuit') {
          const lawsuit = lawsuitStore.lawsuitData?.find(
            (item) => item.id === value,
          )
          if (lawsuit) {
            fields.customer = String(lawsuit.customer.id)
          }
        }
      } else if (typeof value === 'undefined') {
        ;(fields as any)[objKey] = ''
        return
      }
      fields[objKey as keyof typeof fields] = String(value)
    }

    watch(
      () => fields.customer,
      (newVal) => {
        selectedCustomerId.value = newVal
      },
    )

    watch(
      () => router.currentRoute.value,
      () => {
        financeStore.closeCreateModal()
      },
    )

    const {
      isLoading: isNewTransactionLoading,
      data: newTransaction,
      executeApiCall: createTransaction,
      error: newTransactionError,
    } = useApiCall<
      TransactionSuccessResponse,
      DefaultError['error'],
      TransactionItemCreatePayload
    >(createTransactionApiCall, true)
    const {
      isLoading: isEditedTransactionLoading,
      data: editedTransaction,
      executeApiCall: editTransaction,
      error: editedTransactionError,
    } = useApiCall<
      TransactionSuccessResponse,
      DefaultError['error'],
      TransactionItemEditPayload
    >(editTransactionApiCall, true)

    const handleCreateNewTransaction = async () => {
      const body: TransactionItemCreatePayload = {
        type: fields.type as TransactionType,
        amount: fields.amount.replace(/\s+/g, '').replace(/,/, '.'),
        comment: fields.comment,
        date: `${getFormatDateWithDash(fields.date)} ${fields.time}`,
        customerId: fields.customer ? Number(fields.customer) : null,
        lawsuitId: fields.lawsuit ? Number(fields.lawsuit) : null,
      }

      try {
        await createTransaction(body)
        if (newTransaction.value) {
          financeStore.addNewTransaction(newTransaction.value.data)
        }
        savePopupStore.deleteSaveData('TransactionForm')
        financeStore.closeCreateModal()
      } catch (error) {
        if (newTransactionError.value?.data.errors) {
          validationErrors.value = newTransactionError.value?.data.errors
          if (!fields.time) {
            validationErrors.value.time = ['Поле обязательно для заполнения']
          }
          if (!validation.value.time) {
            validationErrors.value.time = ['Некорректное время']
          }
        }
      }
    }
    const handleEditTransaction = async () => {
      const body: TransactionItemEditPayload = {
        id: Number(transactionId.value),
        data: {
          type: fields.type as TransactionType,
          amount: fields.amount.replace(/\s+/g, '').replace(/,/, '.'),
          comment: fields.comment,
          date: `${getFormatDateWithDash(fields.date)} ${fields.time}`,
          customerId: fields.customer ? Number(fields.customer) : null,
          lawsuitId: fields.lawsuit ? Number(fields.lawsuit) : null,
        },
      }

      try {
        await editTransaction(body)
        if (editedTransaction.value) {
          financeStore.replaceTransaction(editedTransaction.value.data)
        }
        financeStore.closeTransactionPanel()
      } catch (error) {
        if (editedTransactionError.value?.data.errors) {
          validationErrors.value = editedTransactionError.value?.data.errors
          if (!fields.time) {
            validationErrors.value.time = ['Время платежа обязательное поле']
          }
        }
      }
    }

    const submitHandler = computed(() =>
      isTransactionNew.value
        ? handleCreateNewTransaction
        : handleEditTransaction,
    )

    watch(
      () => fields,
      () => {
        if (isTransactionNew.value) {
          if (validationErrors.value) {
            Object.keys(fields).forEach((item) => {
              if (
                fields[item as keyof typeof fields] &&
                validationErrors.value &&
                item !== 'email' &&
                item !== 'phone'
              ) {
                delete validationErrors.value[
                  item as keyof typeof validationErrors.value
                ]
              }
            })
          }
          savePopupStore.setSaveData(
            { ...fields },
            'TransactionForm',
            !isSubmitBtnDisabled.value,
          )
        }
      },
      { deep: true },
    )

    watch(
      () => savePopupStore.isSave,
      async () => {
        if (
          savePopupStore.isSave &&
          isTransactionNew.value &&
          savePopupStore.saveData['TransactionForm']
        ) {
          await handleCreateNewTransaction()
          savePopupStore.isSave = false
        }
      },
    )

    onBeforeMount(async () => {
      await lawsuitStore.applyFilter({ isPaginated: 0, status: null })
      const transaction = financeStore.selectedTransaction
      if (transaction) {
        isTransactionNew.value = false

        const { id, type, amount, comment, date, customer, lawsuit } =
          transaction
        transactionId.value = id
        fields.type = type
        fields.amount = String(amount)
        fields.comment = comment ?? ''
        const dateAndTime = getTimezoneDate(date)
        fields.date = dateAndTime.date
        fields.time = dateAndTime.time ?? ''
        fields.customer = customer ? String(customer?.id) : ''
        selectedCustomerId.value = fields.customer
        fields.lawsuit = lawsuit ? String(lawsuit?.id) : ''
      }
      if (
        savePopupStore.saveData['TransactionForm']?.data &&
        isTransactionNew.value
      ) {
        savePopupStore.isLoad = true
      }
    })

    onBeforeUnmount(() => {
      validationErrors.value = null
    })

    return {
      fields,
      validationErrors,
      isTransactionNew,
      selectClientOptions,
      selectLawsuitOptions,
      selectInitialValues,
      isSubmitBtnDisabled,
      isTabletOrMobile,
      TYPES,
      onValueChange,
      submitHandler,
    }
  },
})
