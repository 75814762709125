import MainLayout from '@/layouts/MainLayout/MainLayout.vue'
import AuthLayout from '@/layouts/AuthLayout/AuthLayout.vue'
import {
  computed,
  defineComponent,
  onBeforeMount,
  onMounted,
  onUnmounted,
} from 'vue'
import { useRoute } from 'vue-router'
import { useRouter } from 'vue-router'
import { useAuthStore } from '@/store/auth'
import { useUXUIStore } from '@/store/uxui'
import { useMainStore } from '@/store/main'
import { useEventsStore } from '@/store/events'
import { useCalendarStore } from '@/store/calendar'

export default defineComponent({
  name: 'App',
  components: {
    MainLayout,
    AuthLayout,
  },
  setup() {
    const route = useRoute()
    const router = useRouter()
    const mainStore = useMainStore()
    const authStore = useAuthStore()
    const uxuiStore = useUXUIStore()
    const eventsStore = useEventsStore()
    const calendarStore = useCalendarStore()
    const componentKey = computed(() => {
      return route.path
    })

    const layoutComponent = computed(() => `${route.meta.layout}Layout`)

    const setAppWindowWidth = () => {
      mainStore.$patch({
        windowWidth: window.innerWidth,
      })
    }

    const calculateDeviceUnitHeight = () => {
      if (mainStore.isMobile) {
        const vh = window.innerHeight * 0.01
        document.documentElement.style.setProperty('--vh', `${vh}px`)
      }
    }
    const ALLOWED_COMMON_PAGES = [
      'login',
      'recoveryPassword',
      'updatePassword',
      'registration',
      'adminLogin',
      'adminRecoveryPassword',
      'adminUpdatePassword',
      'logoutUser',
    ]
    const USER_PAGES = [
      'home',
      'lawsuit-details',
      'calendar',
      'profile',
      'clients-table',
      'client-details',
      'finance',
      'notes',
      'settings',
      'tasks',
    ]

    router.beforeEach((to, from, next) => {
      if (
        !authStore.isAuth &&
        !ALLOWED_COMMON_PAGES.includes(String(to.name))
      ) {
        next({ name: 'login' })
        return
      }
      if (uxuiStore.isAdmin && USER_PAGES.includes(String(to.name))) {
        next({ name: 'adminHome' })
        return
      }

      next()
    })

    onMounted(async () => {
      setAppWindowWidth()
      calculateDeviceUnitHeight()
      window.addEventListener('scroll', calculateDeviceUnitHeight)
      window.addEventListener('resize', calculateDeviceUnitHeight)
      window.addEventListener('resize', setAppWindowWidth)
    })

    onBeforeMount(async () => {
      if (authStore.isAuth) {
        await eventsStore.getAllEvents()
      }
      if (eventsStore.eventsList) {
        calendarStore.eventsList = [...eventsStore.eventsList]
      }
    })
    onUnmounted(() => {
      window.removeEventListener('scroll', calculateDeviceUnitHeight)
      window.removeEventListener('resize', calculateDeviceUnitHeight)
      window.removeEventListener('resize', setAppWindowWidth)
    })
    return {
      layoutComponent,
      mainStore,
      componentKey,
      calendarStore,
    }
  },
})
