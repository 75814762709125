import { ModalContent } from '@/types/modals'

export const modalsContent: { [key: string]: ModalContent } = {
  lawsuit: {
    title: 'Удаление дела',
    text: 'Вы уверены, что хотите удалить дело?',
    function: 'deleteLawsuite',
  },
  note: {
    title: 'Удаление заметки',
    text: 'Вы уверены, что хотите удалить данную заметку?',
    function: 'deleteNote',
  },
  authority: {
    title: 'Удаление органа рассмотрения',
    text: 'Вы уверены, что хотите удалить данный орган рассмотрения?',
    function: 'deleteAuthorities',
  },
  taskEvent: {
    title: 'Удаление события/задачи',
    text: 'Вы уверены, что хотите удалить данное событие/задачу?',
    function: 'deleteEventTask',
  },
  task: {
    title: 'Удаление задачи',
    text: 'Вы уверены, что хотите удалить данную задачу?',
    function: 'deleteTask',
  },
  eventType: {
    title: 'Удаление типа события',
    text: 'Вы уверены, что хотите удалить данный тип события?',
    function: 'deleteEventType',
  },
  lawsuitCategory: {
    title: 'Удаление категории дел',
    text: 'Вы уверены, что хотите удалить данную категорию дел?',
    function: 'deleteLawsuitCategory',
  },
  tagForTask: {
    title: 'Удаление тегов для задач',
    text: 'Вы уверены, что хотите удалить данный тег для задач?',
    function: 'deleteTagForTask',
  },
  client: {
    title: 'Удаление клиента',
    text: 'Вы уверены, что хотите удалить данного клиента?',
    function: 'deleteClient',
  },
  finance: {
    title: 'Удаление транзакции',
    text: 'Вы уверены, что хотите удалить данную транзакцию?',
    function: 'deleteTransaction',
  },
  notification: {
    title: 'Удаление уведомления',
    text: 'Вы уверены, что хотите удалить уведомление?',
    function: 'deleteNotification',
  },
  user: {
    title: 'Удаление профиля',
    text: 'Вы уверены, что хотите удалить профиль?',
    function: 'deleteUser',
  },
  userSubscription: {
    title: 'Отмена подписки',
    text: 'Вы уверены, что хотите отменить подписку?',
    cancelButtonTitle: 'Нет',
    agreeButtonTitle: 'Да',
    function: 'cancelSubscription',
  },
  userTariff: {
    title: 'Смена тарифа',
    text: 'Вы уверены, что хотите сменить тариф? Вы будете перенаправлены на экран оплаты',
    cancelButtonTitle: 'Нет',
    agreeButtonTitle: 'Да',
    function: 'changeUserTariff',
    colorScheme: 'green',
  },
  logout: {
    title: 'Выход из профиля',
    text: 'Вы уверены, что хотите выйти?',
    function: 'logout',
    agreeButtonTitle: 'Выйти',
  },
  blockLawyer: {
    title: 'Блокировка пользователя',
    text: 'Вы уверены, что хотите заблокировать данного пользователя?',
    function: 'blockLawyer',
    cancelButtonTitle: 'Нет',
    agreeButtonTitle: 'Да',
    colorScheme: 'green',
  },
  restoreLawyer: {
    title: 'Восстановление пользователя',
    text: 'Вы уверены, что хотите восстановить данного пользователя?',
    function: 'restoreLawyer',
    cancelButtonTitle: 'Нет',
    agreeButtonTitle: 'Да',
    colorScheme: 'green',
  },
  archiveTariff: {
    title: 'Архивирование тарифа',
    text: 'Вы уверены, что хотите архивировать тариф?',
    function: 'archiveTariff',
    agreeButtonTitle: 'Архивировать',
    colorScheme: 'green',
  },
  activateTariff: {
    title: 'Активация тарифа',
    text: 'Вы уверены, что хотите активировать тариф?',
    function: 'activateTariff',
    agreeButtonTitle: 'Активировать',
    colorScheme: 'green',
  },
  deleteTariff: {
    title: 'Удаление тарифа',
    text: 'Вы уверены, что хотите удалить тариф?',
    function: 'deleteTariff',
  },
  deleteConnectTask: {
    title: 'Удаление связанной задачи',
    text: 'Вы уверены, что хотите удалить связанную задачу?',
    function: 'deleteConnectTask',
  },
  deleteConnectEvent: {
    title: 'Удаление связанного события',
    text: 'Вы уверены, что хотите удалить связанное событие?',
    function: 'deleteConnectEvent',
  },
  deleteSession: {
    title: 'Удаление активной сессии',
    text: 'Вы уверены, что хотите удалить активную сессию?',
    function: 'deleteSession',
  },
}
