<template>
  <AppPanel class="lawyer-panel" @close="onClose">
    <div class="lawyer-panel__header">
      <div class="lawyer-panel__header-wrapper">
        <h3>Профиль адвоката</h3>
        <p :class="['lawyer-panel__status', { active: lawyer?.isActive }]">
          {{ lawyer?.isActive ? 'Активен' : 'Заблокирован' }}
        </p>
      </div>
      <SvgIcon icon="x" @click="onClose" />
    </div>
    <div class="bordered lawyer-panel__info">
      <div>
        <span class="label-text">ФИО</span>
        <p>
          {{
            lawyer?.lastname || lawyer?.name || lawyer?.surname
              ? `${lawyer?.lastname ?? ''} ${lawyer?.name ?? ''} ${lawyer?.surname ?? ''}`
              : 'Нет данных'
          }}
        </p>
      </div>
      <div>
        <span class="label-text">Дата регистрации</span>
        <p>{{ lawyer?.createdAt ? getFormatDate(lawyer.createdAt) : '-' }}</p>
      </div>
      <div>
        <span class="label-text">Контактный телефон</span>
        <p>
          {{ lawyer?.phone ? formatPhoneNumber(lawyer?.phone) : 'Нет данных' }}
        </p>
      </div>
      <div>
        <span class="label-text">Контактный E-mail, логин</span>
        <p>{{ lawyer?.email }}</p>
      </div>
    </div>
    <div class="lawyer-panel__tariff">
      <div class="lawyer-panel__tariff-select">
        <span class="lawyer-panel__tariff-select-label">Действующий тариф</span>
        <SelectElement
          :options="tariffsOptions"
          :value="initialTariff"
          objectKey="userTariff"
          :allowEmpty="false"
          placeholder="Выберите название тарифа"
          class="lawyer-panel__tariff-select-menu"
          @dataChanged="onTariffSelect"
        />
      </div>
      <div class="lawyer-panel__info">
        <div>
          <span class="label-text">Размер тарифа, ₽</span>
          <p>
            {{
              lawyer?.subscription?.tariff.cost
                ? formatIntMoney(String(lawyer?.subscription?.tariff.cost))
                : '-'
            }}
          </p>
        </div>
        <div>
          <span class="label-text">Дата оплаты</span>
          <p>
            {{
              lawyer?.subscription?.lastPayment &&
              lawyer?.subscription?.lastPayment.completedAt
                ? getFormatDate(
                    String(lawyer?.subscription?.lastPayment.completedAt),
                  )
                : '-'
            }}
          </p>
        </div>
        <div>
          <span class="label-text">Доход за все время, ₽</span>
          <p>
            {{
              lawyer?.paymentsSum
                ? formatIntMoney(String(lawyer?.paymentsSum))
                : '-'
            }}
          </p>
        </div>
        <div>
          <span class="label-text">Активен до</span>
          <p>
            {{
              lawyer?.subscription?.expireAt
                ? getFormatDate(lawyer?.subscription?.expireAt)
                : '-'
            }}
          </p>
        </div>
      </div>
      <div
        v-if="lawyer?.subscription?.nextTariff"
        class="lawyer-panel__next-tariff"
      >
        <span class="lawyer-panel__tariff-select-label">Будущий тариф</span>
        {{ lawyer?.subscription?.nextTariff?.label }}
        <NotificationsComponent
          :text="`Будет применен ${lawyer?.subscription?.expireAt ? getFormatDate(lawyer?.subscription?.expireAt) : ''}`"
          status="error"
          size="large"
          class="lawyer-panel__footer-message"
        />
      </div>
      <div class="lawyer-panel__sessions">
        <div
          class="lawyer-panel__sessions-header"
          :class="{ pointer: sessions.length }"
          @click="onToggleSessionsSectionOpenState"
        >
          <div>
            <span class="lawyer-panel__tariff-select-label"
              >Активные сеансы
              <span class="lawyer-panel__length-indicator">{{
                sessions.length
              }}</span></span
            >
          </div>
          <SvgIcon
            v-if="sessions.length"
            icon="chevron-down"
            class="lawyer-panel__chevron"
            :class="{ open: isSessionsSectionOpen }"
          />
        </div>
        <ul
          v-if="sessions.length && isSessionsSectionOpen"
          class="lawyer-panel__sessions-list"
        >
          <li
            v-for="{ id, userAgent, updatedAt } of sessions"
            :key="id"
            class="lawyer-panel__sessions-list-item"
          >
            <div>
              <p class="lawyer-panel__sessions-agent">
                {{ userAgent || 'Не указан' }}
              </p>
              <p class="lawyer-panel__sessions-date">
                {{ getFormatDate(updatedAt, true) }}
              </p>
            </div>
            <button
              type="button"
              class="lawyer-panel__sessions-delete-btn"
              @click="onDeleteSessionTokenClick(id)"
            >
              Завершить сеанс
              <SvgIcon icon="x" />
            </button>
          </li>
        </ul>
      </div>
    </div>
    <div class="lawyer-panel__footer">
      <button
        type="button"
        class="lawyer-panel__footer-password"
        :disabled="isUserPasswordRecoveryLoading"
        @click="onSendEmailLinkClick"
      >
        <SvgIcon icon="key" />
        Отправить измененный пароль
      </button>
      <NotificationsComponent
        v-show="panelError"
        :text="panelError ?? ''"
        status="error"
        size="small"
        class="lawyer-panel__footer-message mb"
      />
      <NotificationsComponent
        v-show="userPasswordRecoveryData?.success === 1"
        text="Ссылка на изменение пароля успешно отправлена"
        status="done"
        size="small"
        class="lawyer-panel__footer-message mb"
      />
      <button
        type="button"
        class="lawyer-panel__footer-save"
        :disabled="isUserPasswordRecoveryLoading || isTariffChangeLoading"
        @click="onSaveBtnClick"
      >
        Сохранить изменения
      </button>
    </div>
  </AppPanel>
</template>

<script lang="ts" src="./LawyerPanel.ts"></script>

<style scoped lang="scss" src="./LawyerPanel.scss"></style>
