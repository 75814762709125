import axios from 'axios'
import { UserData } from '@/types/user'
import {
  LawyersListGetPayload,
  LawyersListGetResponse,
  LawyerItemChangeTariffPayload,
  LawyerItemGetDeletePayload,
  LawyerItemGetDeleteResponse,
} from '@/types/lawyers'

export const getLawyersListApiCall = async (
  abortController?: AbortController,
  params?: Partial<LawyersListGetPayload>,
): Promise<LawyersListGetResponse> => {
  const { data } = await axios.get('/v1/admin/user', {
    params: { ...params },
    signal: abortController?.signal,
  })
  return data
}

export const getLawyerItemApiCall = async (
  abortController?: AbortController,
  params?: LawyerItemGetDeletePayload,
): Promise<LawyerItemGetDeleteResponse<UserData>> => {
  const { data } = await axios.get(`/v1/admin/user/${params?.id}`, {
    signal: abortController?.signal,
  })
  return data
}

export const changeLawyerItemTariffApiCall = async (
  abortController?: AbortController,
  params?: LawyerItemChangeTariffPayload,
): Promise<LawyerItemGetDeleteResponse<''>> => {
  const { data } = await axios.post(
    `/v1/admin/user/${params?.id}/update-tariff`,
    { ...params?.data },
    { signal: abortController?.signal },
  )
  return data
}

export const deleteLawyerTokenApiCall = async (
  abortController?: AbortController,
  params?: LawyerItemGetDeletePayload,
): Promise<LawyerItemGetDeleteResponse<''>> => {
  const { data } = await axios.delete(`/v1/admin/active-token/${params?.id}`, {
    signal: abortController?.signal,
  })
  return data
}

export const activateLawyerItemApiCall = async (
  abortController?: AbortController,
  params?: LawyerItemGetDeletePayload,
): Promise<LawyerItemGetDeleteResponse<''>> => {
  const { data } = await axios.post(`/v1/admin/user/${params?.id}/activate`, {
    signal: abortController?.signal,
  })
  return data
}

export const deleteLawyerItemApiCall = async (
  abortController?: AbortController,
  params?: LawyerItemGetDeletePayload,
): Promise<LawyerItemGetDeleteResponse<''>> => {
  const { data } = await axios.delete(`/v1/admin/user/${params?.id}`, {
    signal: abortController?.signal,
  })
  return data
}

export const getStatisticsApiCall = async (
  abortController?: AbortController,
) => {
  const { data } = await axios.get('/v1/admin/statistics', {
    signal: abortController?.signal,
  })
  return data
}
