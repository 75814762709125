import { defineStore } from 'pinia'
import { computed, reactive, ref, watch } from 'vue'
import dayGridPlugin from '@fullcalendar/daygrid'
import interactionPlugin from '@fullcalendar/interaction'
import timeGridPlugin from '@fullcalendar/timegrid'
import ruLocale from '@fullcalendar/core/locales/ru'
import multiMonthPlugin from '@fullcalendar/multimonth'
import { ViewItem } from '@/types/calendar'
import dayjs from 'dayjs'
import { useEventsStore } from '@/store/events'
import {
  EventByIdData,
  EventByIdResponse,
  EventChangePayload,
  EventData,
} from '@/types/events'
import { useUserStore } from '@/store/user'
import { useApiCall } from '@/composables/useApiCall'
import { Error } from '@/types/httpError'
import { changeEventApiCall } from '@/api/events'
import { useUXUIStore } from '@/store/uxui'

export const useCalendarStore = defineStore('calendar', () => {
  const eventsStore = useEventsStore()
  const userStore = useUserStore()
  const currentDate = ref<string>(dayjs(new Date()).format('DD.MM.YYYY'))
  const calendarApi = ref<any>(null)
  const isListView = ref(false)
  const eventsList = ref<(EventData | EventByIdData)[]>([])
  const uxuiStore = useUXUIStore()

  const calendarViews = ref<ViewItem[]>([
    {
      title: 'day',
      active: true,
      value: 'timeGridDay',
    },
    {
      title: 'week',
      active: false,
      value: 'timeGridWeek',
    },
    {
      title: 'month',
      active: false,
      value: 'dayGridMonth',
    },
    {
      title: 'year',
      active: false,
      value: 'multiMonthYear',
    },
    {
      title: 'list',
      active: false,
      value: 'list',
    },
  ])

  const clearData = () => {
    currentDate.value = dayjs(new Date()).format('DD.MM.YYYY')
    calendarApi.value = null
    isListView.value = false
    currentView.value
    calendarViews.value.forEach((item) => {
      item.active = item.value === 'timeGridDay'
    })
  }

  const currentView = computed(() =>
    calendarViews.value.find((item) => item.active),
  )

  const period = computed(() => {
    switch (currentView.value?.title) {
      case 'week':
      case 'month':
      case 'year':
        return [
          dayjs(currentDate.value, 'DD.MM.YYYY')
            .startOf(currentView.value.title)
            .format('DD.MM.YYYY'),
          dayjs(currentDate.value, 'DD.MM.YYYY')
            .endOf(currentView.value.title)
            .format('DD.MM.YYYY'),
        ]
      default:
        return [dayjs(currentDate.value, 'DD.MM.YYYY').format('DD.MM.YYYY')]
    }
  })

  const transformEvents = computed(() => {
    eventsList.value = []
    const events = eventsStore.eventsList?.map((event) => {
      eventsList.value.push(event)
      return {
        id: event.id,
        title: event.theme,
        start: dayjs(event.since).utc().format('YYYY-MM-DD HH:mm:ss'),
        end: event.isAllDay
          ? dayjs(event.since)
              .utc()
              .format(
                `YYYY-MM-DD ${userStore.userInfo?.endWorkingTime || 'HH:mm'}:59`,
              )
          : dayjs(event.till).utc().format('YYYY-MM-DD HH:mm:ss'),
        isTribunal: event.lawsuitEventCategory.type === 'tribunal',
        colorItem: event.lawsuitEventCategory.color,
        isImportant: event.isImportant,
        place: event.place,
        isAllDay: event.isAllDay,
        borderColor: '#EEEEEF',
        client: event.customer?.name || '',
      }
    })
    return events
  })

  const addEvents = () => {
    const events = calendarApi.value.getEvents() as any[]
    if (events.length) {
      events.forEach((item) => {
        item.remove()
      })
    }
    transformEvents.value?.forEach((item) => {
      calendarApi.value.addEvent(item)
    })
  }

  const {
    data: changeTaskData,
    executeApiCall: changeTask,
    error: changeTaskError,
  } = useApiCall<EventByIdResponse, Error, EventChangePayload>(
    changeEventApiCall,
    true,
  )

  const options = reactive({
    plugins: [
      dayGridPlugin,
      interactionPlugin,
      timeGridPlugin,
      multiMonthPlugin,
    ],
    initialView: 'timeGridWeek',
    locale: ruLocale,
    timeZone: 'local',
    initialDate: dayjs(currentDate.value, 'DD.MM.YYYY').format('YYYY-MM-DD'),
    nowIndicator: true,
    contentHeight: 'auto',
    allDaySlot: false,
    headerToolbar: {
      left: calendarViews.value[0].active ? 'title' : '',
      center: '',
      right: '',
    },
    height: 'auto',
    slotDuration: '00:15:00',
    slotLabelInterval: 15,
    expandRows: true,
    slotMinTime: '00:00:00',
    slotMaxTime: '24:00:00',
    stickyHeaderDates: true,
    editable: true,
    selectable: true,
    slotLabelFormat: {
      hour: '2-digit',
      minute: '2-digit',
      omitZeroMinute: false,
      meridiem: 'short',
    },
    views: {
      week: {
        eventMaxStack: 1,
      },
    },
    slotEventOverlap: false,
    longPressDelay: 1,
    events: transformEvents.value,
    eventClassNames: 'myEvent',
    weekends: true,
    eventClick: async function (info: {
      event: { id: string; title: string; start: string }
    }) {
      await openEvent(Number(info.event.id))
    },
    eventDidMount: function (info: any) {
      info.el.style.borderRadius = '12px'
    },
    moreLinkClick: function (info: any) {
      document.documentElement.style.setProperty(
        '--topPopupOffset',
        `${info.jsEvent.pageY - 150}px`,
      )
      document.documentElement.style.setProperty(
        '--leftPopupOffset',
        `${info.jsEvent.pageX - 150}px`,
      )
    },
    eventDrop: async function (info: any) {
      const findEvent = eventsList.value.find(
        (item) => item.id.toString() === info.event.id,
      )
      if (findEvent) {
        const taskBody = {
          id: findEvent.id,
          theme: findEvent.theme || '',
          isImportant: findEvent.isImportant,
          client: findEvent.customer?.name || '',
          sinceDate: info.event.start
            ? dayjs(info.event.start).format('YYYY-MM-DD')
            : '',
          sinceTime: info.event.start
            ? dayjs(info.event.start).format('HH:mm')
            : '',
          tillDate: info.event.end
            ? dayjs(info.event.end).format('YYYY-MM-DD')
            : '',
          tillTime: info.event.end ? dayjs(info.event.end).format('HH:mm') : '',
          cost: findEvent.cost ? findEvent.cost.toString() : null,
          place: findEvent.place,
          lawsuitEventCategoryId: findEvent.lawsuitEventCategory?.id
            ? findEvent.lawsuitEventCategory.id
            : null,
          customerId: findEvent.customer?.id
            ? Number(findEvent.customer.id)
            : null,
          lawsuitId: findEvent.lawsuit?.id
            ? Number(findEvent.lawsuit.id)
            : null,
          comment: findEvent.comment,
          isAllDay: findEvent.isAllDay,
        }
        try {
          await changeTask(taskBody)
          if (changeTaskData.value?.data) {
            reloadEvent(changeTaskData.value?.data)
          }
        } catch (error) {
          info.revert()
          uxuiStore.openNotification(
            'error',
            changeTaskError.value?.data.message || 'Произошла ошибка',
          )
        }
      } else {
        info.revert()
      }
    },
    dateClick: function (info: any) {
      eventsStore.calendarStartData = {
        data: dayjs(info.date).format('DD.MM.YYYY'),
        time:
          currentView.value?.title === 'month' && userStore.userInfo
            ? userStore.userInfo.startWorkingTime
            : dayjs(info.date).format('HH:mm'),
      }
      eventsStore.createEventFormOpen()
    },
  })

  const openEvent = async (id: number) => {
    if (eventsStore.currentEvent?.id !== id) {
      await eventsStore.setCurrentEvent(id)
    } else {
      eventsStore.currentEvent = null
      uxuiStore.eventVisible = false
    }
  }

  const getApi = (ref: any) => {
    if (ref) {
      calendarApi.value = ref.getApi()
      addEvents()
    }
  }

  const changeView = (view: string, date?: string) => {
    if (view === 'list') {
      isListView.value = true
    } else {
      isListView.value = false
      calendarViews.value.forEach((item) => {
        item.active = item.value === view
      })
      calendarApi.value.changeView(view)
      if (date) {
        currentDate.value = date
      } else {
        currentDate.value = dayjs(new Date()).format('DD.MM.YYYY')
      }
    }
  }

  const changeInitialDate = async (type: string) => {
    if (
      type === 'prev' &&
      currentView.value &&
      currentView.value.title !== 'list'
    ) {
      currentDate.value = dayjs(currentDate.value, 'DD.MM.YYYY')
        .subtract(1, currentView.value.title)
        .format('DD.MM.YYYY')
    } else if (currentView.value && currentView.value.title !== 'list') {
      currentDate.value = dayjs(currentDate.value, 'DD.MM.YYYY')
        .add(1, currentView.value.title)
        .format('DD.MM.YYYY')
    }
  }

  const setCurrentData = (date: string) => {
    currentDate.value = date
  }

  const addEvent = async () => {
    await eventsStore.getAllEvents()
  }

  const reloadEvent = (event: EventData | EventByIdData) => {
    let category = null
    if (!event.lawsuit?.lawsuitCategory) {
      category = eventsStore.currentEvent?.lawsuit?.lawsuitCategory
    }
    if (eventsStore.currentEvent) {
      eventsStore.currentEvent = {
        ...eventsStore.currentEvent,
        ...event,
      }
      if (
        category &&
        eventsStore.currentEvent.lawsuit &&
        !eventsStore.currentEvent.lawsuit.lawsuitCategory
      ) {
        eventsStore.currentEvent.lawsuit.lawsuitCategory = { ...category }
      }
    }
    const findItem = eventsList.value.findIndex((item) => item.id === event.id)
    if (findItem !== -1 && transformEvents.value) {
      eventsList.value[findItem] = {
        ...eventsList.value[findItem],
        ...event,
      }
      const events = calendarApi.value.getEvents() as any[]
      if (events.length) {
        events.forEach((item) => {
          if (item.id.toString() === event.id.toString()) {
            item.remove()
            calendarApi.value.addEvent({
              id: eventsList.value[findItem].id,
              title: eventsList.value[findItem].theme,
              start: dayjs(eventsList.value[findItem].since)
                .utc()
                .format('YYYY-MM-DD HH:mm:ss'),
              end: eventsList.value[findItem].isAllDay
                ? dayjs(eventsList.value[findItem].till)
                    .utc()
                    .format(
                      `YYYY-MM-DD ${userStore.userInfo?.endWorkingTime || 'HH:mm'}:59`,
                    )
                : dayjs(event.till).utc().format('YYYY-MM-DD HH:mm:ss'),
              isTribunal:
                eventsList.value[findItem].lawsuitEventCategory.type ===
                'tribunal',
              colorItem: eventsList.value[findItem].lawsuitEventCategory.color,
              isImportant: eventsList.value[findItem].isImportant,
              client: eventsList.value[findItem].customer?.name || '',
              place: eventsList.value[findItem].place,
              isAllDay: eventsList.value[findItem].isAllDay,
              borderColor: '#EEEEEF',
            })
          }
        })
      }
    }
  }

  watch(
    () => currentDate.value,
    async () => {
      if (calendarApi.value) {
        calendarApi.value.gotoDate(
          dayjs(currentDate.value, 'DD.MM.YYYY').format('YYYY-MM-DD'),
        )
      }
    },
  )

  watch(
    () => transformEvents.value,
    () => {
      if (calendarApi.value && transformEvents.value) {
        addEvents()
      }
    },
    { deep: true },
  )

  return {
    options,
    isListView,
    calendarViews,
    changeInitialDate,
    calendarApi,
    getApi,
    changeView,
    currentView,
    period,
    currentDate,
    openEvent,
    setCurrentData,
    transformEvents,
    clearData,
    eventsList,
    reloadEvent,
    addEvent,
  }
})
