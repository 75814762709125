import { defineComponent, ref, computed, watch } from 'vue'
import Multiselect from 'vue-multiselect'

export default defineComponent({
  name: 'AppPagination',
  components: {
    Multiselect,
  },
  props: {
    lastPage: {
      type: Number,
      required: true,
    },
    currentPage: {
      type: Number,
      required: true,
    },
    itemsPerPage: Array<number>,
    selectedItemsPerPage: Number,
    isLoading: Boolean,
  },
  emits: ['itemsPerPageChange', 'selectedPageChange'],
  setup(props, { emit }) {
    const selectedItemsPerPageValue = ref<number | null>(null)
    const selectedPage = ref<number>(1)
    // const buttonsList = computed(() => {
    //   const array = []
    //   let i = 1
    //   while (i <= props.lastPage) {
    //     array.push(i)
    //     i++
    //   }
    //   return array
    // })
    const buttonsList = computed(() => {
      if (props.lastPage <= 5) {
        return props.lastPage
      } else if (props.currentPage < 2) {
        return [1, 2, '...', props.lastPage - 1, props.lastPage]
      } else {
        let arrPages = 0 as (number | string)[] | number

        if (props.lastPage - 5 < props.currentPage) {
          arrPages = Array.from(
            { length: props.lastPage },
            (v, i) => i + 1,
          ).slice(-5)
        } else {
          arrPages = [
            props.currentPage,
            props.currentPage + 1,
            '...',
            props.lastPage - 1,
            props.lastPage,
          ]
        }
        return arrPages
      }
    })

    const isInternalUpdate = ref(false)

    if (props.itemsPerPage) {
      selectedItemsPerPageValue.value = props.itemsPerPage[0]
    }

    const onPrevBtnClick = () => {
      if (selectedPage.value && selectedPage.value > 1) {
        selectedPage.value = selectedPage.value - 1
        emit('selectedPageChange', selectedPage.value)
      }
    }

    const onNextBtnClick = () => {
      if (selectedPage.value < props.lastPage) {
        selectedPage.value = selectedPage.value + 1
        emit('selectedPageChange', selectedPage.value)
      }
    }

    const onPageButtonClick = (page: number | string) => {
      if (page !== selectedPage.value) {
        selectedPage.value = Number(page)
        emit('selectedPageChange', selectedPage.value)
      }
    }

    watch(
      () => props.currentPage,
      (newVal) => {
        selectedPage.value = newVal
      },
    )

    watch(
      () => props.selectedItemsPerPage,
      (newVal) => {
        if (newVal && newVal !== selectedItemsPerPageValue.value) {
          isInternalUpdate.value = true
          selectedItemsPerPageValue.value = newVal
        }
      },
    )

    watch(
      () => selectedItemsPerPageValue.value,
      () => {
        if (isInternalUpdate.value) {
          isInternalUpdate.value = false
          return
        }
        selectedPage.value = 1
        emit('itemsPerPageChange', selectedItemsPerPageValue.value)
      },
    )

    return {
      selectedItemsPerPageValue,
      selectedPage,
      buttonsList,
      onPrevBtnClick,
      onNextBtnClick,
      onPageButtonClick,
    }
  },
})
