import { defineStore } from 'pinia'
import { useApiCall } from '@/composables/useApiCall'
import {
  customersApiCall,
  getClientByIdApiCall,
  getClientLawsuitsApiCall,
  removeClientApiCall,
} from '@/api/customers'
import { RootState, RootGetters, RootActions } from './types'
import { useUXUIStore } from '../uxui'
import { modalsContent } from '@/config/confirmationModals'
import {
  Customer,
  CustomersSearchPayload,
  CustomerResponse,
  CustomersSuccessResponse,
  CustomerLawsuitsPayload,
  CustomerLawsuitsResponseData,
} from '@/types/customers'
import { DefaultError } from '@/types/httpError'

export const useClientsStore = defineStore<
  string,
  RootState,
  RootGetters,
  RootActions
>('clients', {
  state: () => {
    return {
      paginatedClients: null,
      notPaginatedClients: null,
      selectedClient: null,
      lastPage: 1,
      currentPage: 1,
      searchParams: null,
      needBack: false,
    }
  },
  getters: {
    getClientById: (state: RootState) => {
      return (id: number) => {
        return state.paginatedClients?.data.find((client) => client.id === id)
      }
    },
  },
  actions: {
    async getClients(params?: CustomersSearchPayload) {
      const {
        data: allClients,
        executeApiCall: getClientsAction,
        error: allClientsError,
      } = useApiCall<CustomerResponse, DefaultError, CustomersSearchPayload>(
        customersApiCall,
        true,
      )

      if (params) {
        this.searchParams = {
          ...params,
          ...(this.searchParams?.name_sort
            ? {
                name_sort: params.name_sort
                  ? params.name_sort
                  : this.searchParams?.name_sort,
              }
            : {}),
          ...(this.searchParams?.lawsuit_category_name_opponent_sort
            ? {
                lawsuit_category_name_opponent_sort:
                  params.lawsuit_category_name_opponent_sort
                    ? params.lawsuit_category_name_opponent_sort
                    : this.searchParams?.lawsuit_category_name_opponent_sort,
              }
            : {}),
          ...(this.searchParams?.last_active_at_sort
            ? {
                last_active_at_sort: params.last_active_at_sort
                  ? params.last_active_at_sort
                  : this.searchParams?.last_active_at_sort,
              }
            : {}),
        }
      }
      try {
        await getClientsAction({ ...this.searchParams, itemsPerPage: 40 })
        if (allClients.value) {
          if (params && 'isPaginated' in params) {
            this.notPaginatedClients = allClients.value.data
            return
          }
          const { lastPage, currentPage } = allClients.value.meta
          this.currentPage = currentPage
          this.lastPage = lastPage

          if (this.currentPage === 1) {
            this.paginatedClients = allClients.value
            return
          }
          if (Array.isArray(this.paginatedClients?.data)) {
            this.paginatedClients = {
              data: [...this.paginatedClients.data, ...allClients.value.data],
              links: allClients.value.links,
              meta: allClients.value.meta,
            }
          }
        }
      } catch (error) {
        if (allClientsError.value?.data.error) {
          console.log(allClientsError.value?.data.error)
        }
      }
    },
    async loadMoreClients() {
      if (this.currentPage < this.lastPage) {
        this.currentPage++
        let newParams = { page: this.currentPage }
        if (this.searchParams) {
          newParams = { ...this.searchParams, ...newParams }
        }

        await this.getClients(newParams)
      }
    },
    async getClient(params: CustomerLawsuitsPayload) {
      const {
        data: clientData,
        executeApiCall: getClientData,
        error: clientError,
      } = useApiCall<
        CustomersSuccessResponse<Customer>,
        Error,
        CustomerLawsuitsPayload
      >(getClientByIdApiCall, true)

      try {
        await getClientData({ id: params.id })
        if (clientData.value) {
          this.selectedClient = clientData.value.data
        }
        await this.getClientLawsuits({ id: params.id, page: 1 })
      } catch (error) {
        if (clientError.value?.data.message) {
          console.log(clientError.value?.data.message)
        }
      }
    },
    async getClientLawsuits(params: CustomerLawsuitsPayload) {
      const {
        data: clientLawsuits,
        executeApiCall: getClientLawsuitsAction,
        error: clientLawsuitsError,
      } = useApiCall<
        CustomerLawsuitsResponseData,
        DefaultError,
        CustomerLawsuitsPayload
      >(getClientLawsuitsApiCall, true)

      try {
        await getClientLawsuitsAction(params)

        if (!clientLawsuits.value || !this.selectedClient) {
          return
        }

        const { lastPage, currentPage } = clientLawsuits.value
        this.currentPage = currentPage
        this.lastPage = lastPage

        if (this.currentPage === 1) {
          this.selectedClient.lawsuits = clientLawsuits.value.data
          return
        }
        if (this.selectedClient.lawsuits) {
          this.selectedClient.lawsuits = [
            ...this.selectedClient.lawsuits,
            ...clientLawsuits.value.data,
          ]
        }
      } catch (error) {
        if (clientLawsuitsError.value?.data.error) {
          console.log(clientLawsuitsError.value?.data.error)
        }
      }
    },
    async loadMoreClientLawsuits() {
      if (this.currentPage < this.lastPage && this.selectedClient) {
        this.currentPage++

        const newParams = {
          id: this.selectedClient.id,
          page: this.currentPage,
        }
        await this.getClientLawsuits(newParams)
      }
    },
    async removeClientApiRequest(id: number) {
      const { executeApiCall: removeClient, error: removeClientError } =
        useApiCall<
          CustomersSuccessResponse<''>,
          DefaultError,
          CustomerLawsuitsPayload
        >(removeClientApiCall, true)
      try {
        await removeClient({ id })
        this.removeClient(id)
      } catch (error) {
        if (removeClientError.value?.data.error) {
          console.log(removeClientError.value?.data.error)
        }
      }
    },
    replaceClient(id: number, editedClient: Customer) {
      if (!this.paginatedClients?.data) {
        return
      }
      const clientIndex = this.paginatedClients.data.findIndex(
        (item) => item.id === id,
      )
      const currentClient = this.paginatedClients.data[clientIndex]
      this.paginatedClients.data[clientIndex] = {
        ...currentClient,
        ...editedClient,
      }
    },
    addClient(newClient: Customer) {
      if (this.paginatedClients?.data) {
        this.paginatedClients.data = [...this.paginatedClients.data, newClient]
      }
    },
    removeClient(id: number) {
      if (this.paginatedClients?.data) {
        this.paginatedClients.data = this.paginatedClients.data.filter(
          (client) => client.id !== id,
        )
      }
    },
    resetSelectedClient() {
      this.selectedClient = null
    },
    openRemoveModal(id: number) {
      const uxuiStore = useUXUIStore()
      uxuiStore.setModalName('ConfirmationModal')
      uxuiStore.setModalContent(modalsContent['client'], id)
    },
  },
})
