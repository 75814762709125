<template>
  <aside
    :class="[
      'aside',
      {
        collapsed: isAsideCollapsed,
        'calendar-aside': !isAsideCollapsed,
      },
    ]"
  >
    <div class="aside__header">
      <router-link to="/"
        ><SvgIcon class="aside__header-logo" icon="logo"
      /></router-link>
      <button class="aside__burger" @click="togglePanelHandler">
        <SvgIcon icon="menu" />
      </button>
    </div>
    <div class="aside__menu-items">
      <router-link
        v-for="item in menuItems"
        :to="item.route"
        :class="['aside__menu-item', { collapsed: isAsideCollapsed }]"
        :key="item.id"
        @click="selectMenuHandler"
      >
        <SvgIcon :icon="item.icon" />
        <p class="aside__menu-item--text">{{ item.title }}</p>
      </router-link>
    </div>
    <div v-show="!isAsideCollapsed" class="calendar-aside__wrapper">
      <DateElement
        class="event-form__middle-time-picker"
        :value="calendarStore.currentDate"
        :month-change-on-scroll="false"
        inline
        :no-today="false"
        :monthPicker="calendarStore.currentView?.title === 'month'"
        :dayPicker="calendarStore.currentView?.title === 'day' || !isCalendar"
        :yearPicker="calendarStore.currentView?.title === 'year'"
        :weekPicker="calendarStore.currentView?.title === 'week' && isCalendar"
        month-name-format="long"
        object-key="calendarPicker"
        @dataChanged="onValueChange"
        isCalendar
      />
      <button @click="getToday" class="calendar-aside__today">Сегодня</button>
    </div>
  </aside>
</template>

<script lang="ts" src="./AsidePanel.ts"></script>
<style lang="scss" scoped src="./AsidePanel.scss"></style>
