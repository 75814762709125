import { defineComponent, ref, reactive, computed, watch } from 'vue'
import { useTariffsStore } from '@/store/tariffs'
import { useUXUIStore } from '@/store/uxui'
import { useApiCall } from '@/composables/useApiCall'
import { createTariffItemApiCall, editTariffItemApiCall } from '@/api/tariff'
import {
  TariffStatus,
  TariffItemCreatePayload,
  TariffItemEditPayload,
  TariffItemCreateEditResponse,
} from '@/types/tariff'
import { formatDecMoney } from '@/helpers/formatMoney'
import { DefaultError } from '@/types/httpError'

export default defineComponent({
  name: 'TariffForm',
  setup() {
    type TariffFormFieldsType = {
      label: string
      cost: string
      comment: string
      permissionIds: number[] | null
      status: TariffStatus
      duration: { id: number; name: string } | null
    }
    type ValidationErrorsKeys = Partial<typeof fields>

    const fields = reactive<TariffFormFieldsType>({
      label: '',
      cost: '',
      comment: '',
      permissionIds: null,
      status: 'active',
      duration: null,
    })
    const isTariffNew = ref(false)
    const tariffId = ref<number | null>(null)
    const validationErrors = ref<
      { [K in keyof ValidationErrorsKeys]?: string[] } | null
    >(null)
    const mainError = ref('')
    const tariffsStore = useTariffsStore()
    const uiuxStore = useUXUIStore()

    const STATUSES = [
      { label: 'Активен', value: 'active' },
      { label: 'Архивный', value: 'archive' },
    ]
    const CHECK_DATA_MESSAGE = 'Проверьте корректность введенных данных'

    const DURATION_OPTIONS = [
      { id: 1, name: '1 месяц' },
      { id: 2, name: '3 месяца' },
      { id: 3, name: '6 месяцев' },
      { id: 4, name: '9 месяцев' },
      { id: 5, name: '12 месяцев' },
    ]

    const VIEW_PERMISSIONS = [
      { value: null, key: 'lawsuits' },
      { value: null, key: 'calendar' },
      { value: null, key: 'tasks' },
      { value: null, key: 'notes' },
      { value: null, key: 'customers' },
      { value: null, key: 'finances' },
    ]
    const MANAGE_PERMISSIONS = [
      { label: 'Дела', value: null, key: 'lawsuits' },
      { label: 'Календарь', value: null, key: 'calendar' },
      { label: 'Задачи', value: null, key: 'tasks' },
      { label: 'Быстрые заметки', value: null, key: 'notes' },
      { label: 'Клиенты', value: null, key: 'customers' },
      { label: 'Финансы', value: null, key: 'finances' },
    ]
    const viewPermissionsArray = computed(() => {
      return VIEW_PERMISSIONS.map((permission) => ({
        ...permission,
        value: tariffsStore.appStat?.permissions.filter(
          (stat) =>
            stat.name.includes('view') && stat.name.includes(permission.key),
        )[0].id,
      }))
    })
    const managePermissionsArray = computed(() => {
      return MANAGE_PERMISSIONS.map((permission) => ({
        ...permission,
        value: tariffsStore.appStat?.permissions.filter(
          (stat) =>
            stat.name.includes('manage') && stat.name.includes(permission.key),
        )[0].id,
      }))
    })

    const filteredPermissions = computed(() =>
      !isTariffNew.value
        ? managePermissionsArray.value.filter(
            ({ value }) => value && fields.permissionIds?.includes(value),
          )
        : [],
    )

    const resetErrors = () => {
      mainError.value = ''
      validationErrors.value = null
    }

    const findDuration = (key: string, value: string) => {
      return DURATION_OPTIONS.find((opt) =>
        opt[key as keyof typeof opt].toString().startsWith(value),
      )
    }

    watch(
      () => tariffsStore.selectedTariff,
      (newVal) => {
        resetErrors()
        if (newVal) {
          isTariffNew.value = false
          const { id, label, cost, comment, permissions, status, duration } =
            newVal
          tariffId.value = id
          fields.label = label
          fields.cost = String(cost)
          fields.comment = comment ?? ''
          fields.permissionIds = permissions.map(({ id }) => id)
          fields.status = status
          fields.duration = duration
            ? (findDuration('name', duration.toString()) ?? null)
            : null
        } else {
          isTariffNew.value = true
          tariffId.value = null
        }
      },
      { immediate: true },
    )

    const onValueChange = (key: keyof typeof fields, value: any) => {
      if (key === 'permissionIds') {
        if (!fields.permissionIds) {
          fields.permissionIds = []
        }
        if (fields.permissionIds.includes(value)) {
          fields.permissionIds = fields.permissionIds.filter(
            (item) => item !== value,
          )
        } else {
          fields[key]?.push(value)
        }
        return
      }

      if (key === 'duration') {
        fields[key] = findDuration('id', value) ?? null
        return
      }

      fields[key] = value
    }

    const configurePermissionIds = () => {
      const leftPermissions = managePermissionsArray.value.filter(
        (permission) =>
          !fields.permissionIds?.some((item) => item === permission.value),
      )
      const viewAnalogs = viewPermissionsArray.value.filter((item) =>
        leftPermissions.some((lefty) => lefty.key === item.key),
      )
      const viewAnalogsIds = viewAnalogs.map((item) => Number(item.value))
      return viewAnalogsIds
    }

    const {
      isLoading: createNewTariffLoading,
      data: newTariffData,
      executeApiCall: createNewTariff,
      error: newTariffError,
    } = useApiCall<
      TariffItemCreateEditResponse,
      DefaultError['error'],
      TariffItemCreatePayload
    >(createTariffItemApiCall, true)

    const {
      isLoading: editTariffLoading,
      data: editedTariffData,
      executeApiCall: editTariff,
      error: editedTariffError,
    } = useApiCall<
      TariffItemCreateEditResponse,
      DefaultError['error'],
      TariffItemEditPayload
    >(editTariffItemApiCall, true)

    const handleCreateNewTariff = async () => {
      resetErrors()
      const body = {
        ...fields,
        cost: fields.cost.replace(/\s+/g, ''),
        permissionIds: fields.permissionIds
          ? [...fields.permissionIds, ...configurePermissionIds()]
          : [],
        name: fields.label,
        duration: fields.duration
          ? Number(fields.duration.name.match(/\d+/g)?.join(''))
          : null,
      }
      try {
        await createNewTariff(body)
        if (newTariffData.value?.success === 1) {
          await tariffsStore.getAllTariffs({ page: 1 })
          uiuxStore.setModalName('')
        }
      } catch (error) {
        if (
          newTariffError.value?.status === 422 &&
          newTariffError.value?.data.errors
        ) {
          mainError.value = newTariffError.value.data.message?.includes(
            'Имя уже занято',
          )
            ? 'Такой тариф уже есть в БД'
            : CHECK_DATA_MESSAGE
          validationErrors.value = newTariffError.value?.data.errors
          return
        }
        if (newTariffError.value?.data.message) {
          mainError.value = newTariffError.value?.data.message
        }
      }
    }

    const checkIfSectionsSelected = () => {
      const manageIds = managePermissionsArray.value.map(({ value }) => value)
      return fields.permissionIds?.some((item) => manageIds.includes(item))
    }

    const handleTariffEdit = async () => {
      resetErrors()
      if (!tariffId.value) {
        return
      }
      if (!checkIfSectionsSelected()) {
        mainError.value = CHECK_DATA_MESSAGE
        return
      }
      const body = {
        id: tariffId.value,
        data: {
          ...fields,
          cost: fields.cost.replace(/\s+/g, ''),
          permissionIds: fields.permissionIds
            ? [...fields.permissionIds, ...configurePermissionIds()]
            : [],
          name: fields.label,
          duration: fields.duration
            ? Number(fields.duration.name.match(/\d+/g)?.join(''))
            : null,
        },
      }
      try {
        await editTariff(body)
        if (editedTariffData.value?.success === 1) {
          await tariffsStore.getAllTariffs({ page: 1 })
          tariffsStore.closePanel()
        }
      } catch (error) {
        if (
          editedTariffError.value?.status === 422 &&
          editedTariffError.value?.data.errors
        ) {
          mainError.value = editedTariffError.value.data.message?.includes(
            'Имя уже занято',
          )
            ? 'Такой тариф уже есть в БД'
            : CHECK_DATA_MESSAGE
          validationErrors.value = editedTariffError.value?.data.errors
          return
        }
        if (editedTariffError.value?.data.message) {
          mainError.value = editedTariffError.value?.data.message
        }
      }
    }

    const isSubmitBtnDisabled = computed(
      () =>
        !fields.label ||
        !fields.cost ||
        !fields.permissionIds ||
        !fields.permissionIds.length ||
        createNewTariffLoading.value ||
        editTariffLoading.value,
    )
    const submitHandler = computed(() =>
      isTariffNew.value ? handleCreateNewTariff : handleTariffEdit,
    )

    return {
      formatDecMoney,
      fields,
      isTariffNew,
      STATUSES,
      DURATION_OPTIONS,
      managePermissionsArray,
      filteredPermissions,
      validationErrors,
      mainError,
      onValueChange,
      isSubmitBtnDisabled,
      submitHandler,
    }
  },
})
