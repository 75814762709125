import { defineStore } from 'pinia'
import { ref, computed, reactive } from 'vue'
import { DefaultError, Errors } from '@/types/httpError'
import { useApiCall } from '@/composables/useApiCall'
import { useUXUIStore } from '@/store/uxui'
import { useClientsStore } from '@/store/client'
import {
  LawsuitForm,
  LawsuitFormPayload,
  LawsuitPartialFormObject,
  LawsuitsFormSuccessResponse,
  LawsuitFormSuccessResponse,
  LawsuitFormObject,
  TaskEventResponse,
  LawsuitCategory,
  LawsuitCategoryResponseData,
  StatusType,
  CreateLawsuitCategoryResponseData,
  Event,
  LawsuitEventFormPayload,
} from '@/types/lawsuit'
import { Task } from '@/types/tasks'
import {
  lawsuitApiCall,
  lawsuitChangeApiCall,
  lawsuitCreateApiCall,
  lawsuitEventsApiCall,
  deleteLawsuitApiCall,
  lawsuitPartialChangeApiCall,
  lawsuitCategoryesListApiCall,
  createLawsuitCategoryesApiCall,
  editLawsuitCategoryApiCall,
  deleteLawsuitCategoryApiCall,
} from '@/api/lawsuit'
import { useSavePopupStore } from '@/store/savePopup'
import { useRoute } from 'vue-router'

export const useLawsuitStore = defineStore('lawsuit', () => {
  const lawsuitData = ref<LawsuitForm[]>([])
  const statusResponse = ref(false)
  const errorFields = ref<{ id: number | string; error: Errors }[]>([])
  const lawsuitEvents = ref<(Task | Event)[]>([])
  const uxuiStore = useUXUIStore()
  const isUpdateEvent = ref(false)
  const savePopupStore = useSavePopupStore()
  const clientsStore = useClientsStore()
  const filterLawsuit = reactive<LawsuitFormPayload>({
    opponentSort: null,
    page: 1,
    itemsPerPage: 50,
    customerNameSort: null,
    authorityAuthoritySort: null,
    lawsuitCategoryNameSort: null,
    tasksCountSort: null,
    lawsuitEventsCountSort: null,
    customerIds: [],
    lawsuitCategoryIds: [],
    upcomingEventSort: null,
    opponents: [],
    phrase: '',
    status: 'planned',
    lawsuitEventCategoryIds: [],
    createdAtSort: null,
    ratingSort: 'desc',
    isPaginated: 1,
    lawsuitEventCategoryTypeTribunalSort: null,
  })
  const route = useRoute()
  const eventFields = reactive({
    id: route.params.id,
    filter: {
      page: 1,
      status: 'planned',
    },
  }) as LawsuitEventFormPayload
  const lawsuitPages = reactive({
    lastPage: 0,
    currentPage: 0,
  })
  // Формирование данных для настроек
  const lawsuitCategories = ref<LawsuitCategory[] | null>(null)
  const arrayOfLawsuitCategories = ref<LawsuitCategory[]>([])
  const isUpdate = ref(false)

  const setLawsuitCategories = (payload: LawsuitCategory[] | null): void => {
    lawsuitCategories.value = payload
  }

  // Дело для отправки запроса на изменение или создание
  const selectedLawsuitData = ref<LawsuitFormObject | null>(
    {} as LawsuitFormObject,
  )

  // Дело для отображения на странице
  const selectedLawsuit = ref<LawsuitForm | null>(null)

  // Заполнение объекта по ключу и значению
  const setSelectedLawsuitData = (
    key: string,
    value: number | string | null,
  ): void => {
    if (selectedLawsuitData.value) {
      selectedLawsuitData.value[key] = value
    }
  }

  // Заполнение объекта целиком
  const setSelectedLawsuitDataObject = (
    payload: LawsuitFormObject | null,
  ): void => {
    selectedLawsuitData.value = payload
  }

  const setSelectedLawsuit = (payload: LawsuitForm | null): void => {
    selectedLawsuit.value = payload
  }

  // const setLawsuitEvents = (payload: TaskEvent[] | null): void => {
  //   if (payload) {
  //     lawsuitEvents.value = payload.map(item => item.task ? item.task : item.event)
  //   }
  // }

  // Формирование массива с измененными объектами
  const saveLawsuitCategoryObject = (newObject: LawsuitCategory): void => {
    if (arrayOfLawsuitCategories.value) {
      const index = arrayOfLawsuitCategories.value.findIndex(
        (item) => item.id === newObject.id,
      )
      if (index !== -1) {
        arrayOfLawsuitCategories.value[index] = {
          ...arrayOfLawsuitCategories.value[index],
          ...newObject,
        }
      } else {
        arrayOfLawsuitCategories.value.push(newObject)
      }
    }
  }

  const {
    data: lawsuitsData,
    executeApiCall: getLawsuits,
    isLoading: lawsuitsDataLoading,
    error: lawsuitsError,
  } = useApiCall<LawsuitsFormSuccessResponse, DefaultError, LawsuitFormPayload>(
    lawsuitApiCall,
    true,
  )

  const {
    data: lawsuitDeleteData,
    executeApiCall: deleteAction,
    error: lawsuitDeleteError,
    isLoading: isDeleteLoading,
  } = useApiCall<LawsuitFormSuccessResponse, DefaultError, LawsuitFormPayload>(
    deleteLawsuitApiCall,
    true,
  )

  const isDeleteLawsuitLoading = computed(() => isDeleteLoading.value)

  const {
    data: lawsuitPartialData,
    executeApiCall: sendPartialData,
    error: lawsuitError,
  } = useApiCall<
    LawsuitFormSuccessResponse,
    DefaultError,
    LawsuitPartialFormObject
  >(lawsuitPartialChangeApiCall, true)

  const {
    data: lawsuitChangedData,
    executeApiCall: sendData,
    error: lawsuitChangedError,
  } = useApiCall<LawsuitFormSuccessResponse, DefaultError, LawsuitFormObject>(
    lawsuitChangeApiCall,
    true,
  )

  const {
    data: lawsuitCreateData,
    executeApiCall: createData,
    error: lawsuitCreateError,
  } = useApiCall<LawsuitFormSuccessResponse, DefaultError, LawsuitFormObject>(
    lawsuitCreateApiCall,
    true,
  )

  const {
    data: lawyerEventsData,
    executeApiCall: getEvents,
    error: lawyerEventsError,
    isLoading: isEventsLoading,
  } = useApiCall<TaskEventResponse, DefaultError, LawsuitEventFormPayload>(
    lawsuitEventsApiCall,
    true,
  )
  const planningLoading = computed(() => isEventsLoading.value)
  const {
    data: lawsuitCategoriesData,
    executeApiCall: getLawsuitCategories,
    error: lawsuitCategoriesError,
  } = useApiCall<LawsuitCategoryResponseData, DefaultError, LawsuitFormPayload>(
    lawsuitCategoryesListApiCall,
    true,
  )

  const {
    data: createdLawsuitCategoriesData,
    executeApiCall: createLawsuitCategories,
    error: lawsuitCreateCategoriesError,
  } = useApiCall<
    CreateLawsuitCategoryResponseData,
    DefaultError,
    LawsuitCategory
  >(createLawsuitCategoryesApiCall, true)

  const {
    // data: editLawsuitCategoriesData,
    executeApiCall: editLawsuitCategories,
    error: lawsuitEditCategoriesError,
  } = useApiCall<LawsuitCategoryResponseData, DefaultError, LawsuitCategory>(
    editLawsuitCategoryApiCall,
    true,
  )

  const {
    data: deleteLawsuitCategoriesData,
    executeApiCall: deleteCategory,
    error: lawsuitDeleteCategoriesError,
  } = useApiCall<LawsuitCategoryResponseData, DefaultError, LawsuitFormPayload>(
    deleteLawsuitCategoryApiCall,
    true,
  )
  const isLawsuitsLoading = computed(() => lawsuitsDataLoading.value)
  const isLawsuitDeleteLoading = computed(() => isDeleteLoading.value)

  const changeTabLawsuits = async (value: string) => {
    filterLawsuit.status = value
    filterLawsuit.page = 1
    lawsuitData.value = []
    await getLawsuitsItems()
  }
  const updateList = async () => {
    if (filterLawsuit.page) {
      const arrIterable = [...Array(filterLawsuit.page)].map((e, i) => i + 1)
      isUpdate.value = true
      lawsuitData.value = []
      for await (const i of arrIterable) {
        filterLawsuit.page = i
        await getLawsuitsItems()
      }
      isUpdate.value = false
    }
  }

  const getLawsuitsItems = async () => {
    const filter = {} as LawsuitFormPayload
    Object.keys(filterLawsuit).forEach((key) => {
      if (
        key === 'customerIds' ||
        key === 'lawsuitCategoryIds' ||
        key === 'opponents' ||
        key === 'lawsuitEventCategoryIds'
      ) {
        if (filterLawsuit[key as keyof LawsuitFormPayload].length) {
          filter[key as keyof LawsuitFormPayload] = filterLawsuit[key]
        } else {
          return
        }
      } else if (
        filterLawsuit[key as keyof LawsuitFormPayload] ||
        (key === 'isPaginated' && !filterLawsuit.isPaginated)
      ) {
        filter[key as keyof LawsuitFormPayload] =
          filterLawsuit[key as keyof LawsuitFormPayload]
      }
    })
    try {
      if (
        (filter.page && filter.page <= lawsuitPages.lastPage) ||
        !lawsuitPages.lastPage
      ) {
        await getLawsuits(filter)
        if (lawsuitsData.value?.data) {
          lawsuitPages.lastPage = lawsuitsData.value.meta.lastPage
          lawsuitPages.currentPage = lawsuitsData.value.meta.currentPage
          lawsuitData.value = [...lawsuitData.value, ...lawsuitsData.value.data]
        }
      }
    } catch {
      if (lawsuitsError.value?.data?.error) {
        uxuiStore.openNotification(
          'error',
          (lawsuitsError.value.data.error as string) || 'Произошла ошибка',
        )
      }
    }
  }

  const applyFilter = async (filter: LawsuitFormPayload) => {
    for (const key in filter) {
      filterLawsuit[key as keyof LawsuitFormPayload] = filter[key]
    }
    filterLawsuit.page = 1
    lawsuitData.value = []
    isUpdate.value = true
    await getLawsuitsItems()
    isUpdate.value = false
  }

  const deleteFilter = async (key: string) => {
    filterLawsuit[key as keyof LawsuitFormPayload] = []
    filterLawsuit.page = 1
    isUpdate.value = true
    lawsuitData.value = []
    await getLawsuitsItems()
    isUpdate.value = false
  }

  const changeSort = async (key: string, value: string | null | boolean) => {
    for (const keyItem in filterLawsuit) {
      if (keyItem.includes('Sort')) {
        filterLawsuit[keyItem] = null
      }
    }
    if (value) {
      filterLawsuit[key as keyof LawsuitFormPayload] = value
    } else {
      filterLawsuit.ratingSort = 'desc'
    }
    filterLawsuit.page = 1
    lawsuitData.value = []
    await getLawsuitsItems()
  }

  const getLawsuitList = async () => {
    if (
      isUpdate.value ||
      (lawsuitPages.currentPage &&
        lawsuitPages.currentPage === lawsuitPages.lastPage)
    ) {
      return
    }
    if (lawsuitsData.value) {
      const { meta } = lawsuitsData.value
      if (lawsuitData.value?.length < meta.total && filterLawsuit.page) {
        filterLawsuit.page++
        await getLawsuitsItems()
      }
    } else {
      await getLawsuitsItems()
    }
  }

  const changeLawsuitList = (changedObject: LawsuitForm) => {
    if (!lawsuitData.value) {
      lawsuitData.value = []
    }
    const existingObjectIndex = lawsuitData.value.findIndex(
      (obj) => obj.id === changedObject.id,
    )
    if (existingObjectIndex !== -1) {
      const existingObject = lawsuitData.value[existingObjectIndex]
      lawsuitData.value[existingObjectIndex] = {
        ...existingObject,
        ...changedObject,
      }
    } else {
      lawsuitData.value.push(changedObject)
    }
  }

  const deleteLawsuit = async (idToRemove: number) => {
    if (lawsuitData.value) {
      lawsuitData.value = lawsuitData.value.filter(
        (item: LawsuitForm) => item.id !== idToRemove,
      )
    }
  }

  const removeLawsuitCategory = (objectToRemove: LawsuitFormPayload) => {
    if (lawsuitCategories.value) {
      lawsuitCategories.value = lawsuitCategories.value.filter(
        (object) => object.id !== objectToRemove.id,
      )
    }
  }

  const setEmptyLawsuitCategory = (): void => {
    const emptyLawsuitCategory: LawsuitCategory = {
      id: 'new' + Date.now(),
      name: '',
      color: '',
      notifyBeforeHours: null,
      markBeforeDays: null,
      createdAt: null,
      updatedAt: null,
    }
    if (lawsuitCategories.value) {
      lawsuitCategories.value.push(emptyLawsuitCategory)
    } else {
      lawsuitCategories.value = [emptyLawsuitCategory]
    }
  }

  const processArrayObjects = async () => {
    errorFields.value = []
    if (Array.isArray(arrayOfLawsuitCategories.value)) {
      for (const item of arrayOfLawsuitCategories.value) {
        if (!item.id?.toString().includes('new')) {
          await editLawsuitCategoriesObject(item)
        } else {
          await createLawsuitCategoriesObject(item)
        }
      }
    }
    if (!errorFields.value.length) {
      arrayOfLawsuitCategories.value = []
    }
  }

  const deletelawsuitItem = async (payload: LawsuitFormPayload) => {
    if (payload && payload.id && !payload.id?.toString().includes('new')) {
      try {
        await deleteAction(payload)
        if (lawsuitDeleteData.value) {
          statusResponse.value = lawsuitDeleteData.value.success
          await updateList()
        }
      } catch {
        if (lawsuitDeleteError.value?.data?.error) {
          uxuiStore.openNotification(
            'error',
            (lawsuitDeleteError.value.data.error as string) ||
              'Произошла ошибка',
          )
        }
      }
    }
  }

  const setChangeLawsuit = async () => {
    try {
      if (selectedLawsuitData.value) {
        await sendData(selectedLawsuitData.value)
        if (lawsuitChangedData.value && lawsuitChangedData.value.success) {
          changeLawsuitList(lawsuitChangedData.value.data)
          if (route.name === 'home') {
            await updateList()
          }
          uxuiStore.setModalName('')
        }
      }
    } catch {
      if (lawsuitChangedError.value?.data?.error) {
        uxuiStore.openNotification(
          'error',
          (lawsuitChangedError.value.data.error as string) ||
            'Произошла ошибка',
        )
      }
    }
  }
  const createErrors = ref<{ [key: string]: string[] } | null>(null)
  const setCreateLawsuit = async () => {
    try {
      if (selectedLawsuitData.value) {
        await createData(selectedLawsuitData.value)
        if (lawsuitCreateData.value && lawsuitCreateData.value.success) {
          changeLawsuitList(lawsuitCreateData.value.data)
          if (clientsStore.selectedClient) {
            clientsStore.selectedClient = null
          }
          savePopupStore.deleteSaveData('EditLawsuit')
          if (route.name === 'home') {
            await updateList()
          }
          uxuiStore.setModalName('')
        }
      }
    } catch {
      if (lawsuitCreateError.value?.data?.error?.errors) {
        createErrors.value = lawsuitCreateError.value?.data?.error.errors
      }
    }
  }
  const lastEventPages = reactive({
    lastPage: 0,
    currentPage: 0,
  })

  const getMoreLawsuitEvents = async () => {
    if (
      !isUpdateEvent.value &&
      lastEventPages.currentPage &&
      lastEventPages.lastPage !== lastEventPages.currentPage &&
      lawyerEventsData.value
    ) {
      eventFields.filter.page++
      await getLawsuitEvents()
    }
  }

  const getLawsuitEvents = async () => {
    try {
      await getEvents(eventFields)
      if (lawyerEventsData.value) {
        lastEventPages.lastPage = lawyerEventsData.value.lastPage
        lastEventPages.currentPage = lawyerEventsData.value.currentPage
        lawsuitEvents.value = [
          ...lawsuitEvents.value,
          ...lawyerEventsData.value.data.map((item) =>
            item.task ? item.task : item.event,
          ),
        ]
        isUpdateEvent.value = false
      }
    } catch {
      if (lawyerEventsError.value?.data?.error) {
        uxuiStore.openNotification(
          'error',
          (lawyerEventsError.value.data.error as string) || 'Произошла ошибка',
        )
      }
    }
  }

  const setPartialChangeLawsuit = async (payload: LawsuitPartialFormObject) => {
    try {
      await sendPartialData(payload)
      if (lawsuitPartialData.value) {
        lawsuitData.value = lawsuitData.value.filter(
          (item) => item.id !== payload.id,
        )
        await updateList()
        uxuiStore.setModalName('')
      }
    } catch {
      if (lawsuitError.value?.data?.error) {
        uxuiStore.openNotification(
          'error',
          (lawsuitError.value.data.error as string) || 'Произошла ошибка',
        )
      }
    }
  }

  const getLawsuitCategoriesList = async () => {
    try {
      await getLawsuitCategories()
      if (lawsuitCategoriesData.value && lawsuitCategoriesData.value.success) {
        setLawsuitCategories(lawsuitCategoriesData.value.data)
      }
    } catch {
      if (lawsuitCategoriesError.value?.data?.error) {
        uxuiStore.openNotification(
          'error',
          (lawsuitCategoriesError.value.data.error as string) ||
            'Произошла ошибка',
        )
      }
    }
  }

  const createLawsuitCategoriesObject = async (
    payload: LawsuitCategory | null,
  ) => {
    if (payload) {
      try {
        await createLawsuitCategories(payload)
        if (
          createdLawsuitCategoriesData.value &&
          createdLawsuitCategoriesData.value.success &&
          lawsuitCategories.value
        ) {
          const findItem = lawsuitCategories.value?.findIndex(
            (item) => item.id === payload.id,
          )
          if (findItem !== -1) {
            lawsuitCategories.value[findItem] = {
              ...createdLawsuitCategoriesData.value.data,
            }
          }
        }
      } catch {
        if (lawsuitCreateCategoriesError.value?.data.error.errors) {
          errorFields.value.push({
            id: payload.id,
            error: lawsuitCreateCategoriesError.value.data.error.errors,
          })
        }
      }
    }
  }

  const editLawsuitCategoriesObject = async (
    payload: LawsuitCategory | null,
  ) => {
    if (payload) {
      try {
        await editLawsuitCategories(payload)
      } catch {
        if (lawsuitEditCategoriesError.value?.data.error.errors) {
          errorFields.value.push({
            id: payload.id,
            error: lawsuitEditCategoriesError.value.data.error.errors,
          })
        }
      }
    }
  }

  const deleteLawsuitCategory = async (payload: LawsuitFormPayload) => {
    if (payload && payload.id) {
      try {
        await deleteCategory(payload)
        if (deleteLawsuitCategoriesData.value?.success) {
          if (payload) {
            removeLawsuitCategory(payload)
          }
        }
      } catch {
        if (lawsuitDeleteCategoriesError.value?.data?.error) {
          uxuiStore.openNotification(
            'error',
            (lawsuitDeleteCategoriesError.value.data.error as string) ||
              'Произошла ошибка',
          )
        }
      }
      // Если категория на стадии создания и ее нет в БД
    } else {
      removeLawsuitCategory(payload)
    }
  }

  const clearData = () => {
    lawsuitData.value = []
    lawsuitsData.value = null
    lawyerEventsData.value = null
    lawsuitPages.lastPage = 0
    lawsuitPages.currentPage = 0

    Object.assign(filterLawsuit, {
      opponentSort: null,
      page: 1,
      itemsPerPage: 50,
      customerNameSort: null,
      authorityAuthoritySort: null,
      lawsuitCategoryNameSort: null,
      tasksCountSort: null,
      lawsuitEventsCountSort: null,
      customerIds: [],
      lawsuitCategoryIds: [],
      upcomingEventSort: null,
      opponents: [],
      phrase: '',
      status: 'planned',
      lawsuitEventCategoryIds: [],
      createdAtSort: null,
      ratingSort: 'desc',
      isPaginated: 1,
      lawsuitEventCategoryTypeTribunalSort: null,
    })
  }

  const setChangeStatusInArray = (
    id: number,
    type: string,
    status: StatusType,
  ) => {
    console.log(id, status, type)
    // if (lawsuitEvents.value) {
    //   const item = lawsuitEvents.value.find(
    //     (item) =>
    //       (type === 'task' && item.task && item.task.id === id) ||
    //       (type === 'event' && item.event && item.event.id === id),
    //   )
    //   if (item) {
    //     if (item.task) {
    //       item.task.status = status
    //     } else if (item.event) {
    //       item.event.status = status
    //     }
    //   }
    // }
  }

  return {
    lawsuitData,
    isLawsuitDeleteLoading,
    getLawsuitList,
    deletelawsuitItem,
    changeLawsuitList,
    selectedLawsuitData,
    setSelectedLawsuitData,
    deleteLawsuit,
    setPartialChangeLawsuit,
    setChangeLawsuit,
    selectedLawsuit,
    setSelectedLawsuit,
    setCreateLawsuit,
    setSelectedLawsuitDataObject,
    lawsuitEvents,
    // setLawsuitEvents,
    getLawsuitEvents,
    getLawsuitCategoriesList,
    lawsuitCategories,
    setEmptyLawsuitCategory,
    createLawsuitCategoryesApiCall,
    editLawsuitCategoryApiCall,
    createLawsuitCategoriesObject,
    editLawsuitCategoriesObject,
    processArrayObjects,
    arrayOfLawsuitCategories,
    saveLawsuitCategoryObject,
    deleteLawsuitCategory,
    setChangeStatusInArray,
    isLawsuitsLoading,
    changeTabLawsuits,
    changeSort,
    filterLawsuit,
    applyFilter,
    deleteFilter,
    isDeleteLawsuitLoading,
    clearData,
    errorFields,
    updateList,
    createErrors,
    planningLoading,
    eventFields,
    getMoreLawsuitEvents,
    isUpdateEvent,
    lawyerEventsData,
    getLawsuitsItems,
  }
})
