import { computed, defineComponent } from 'vue'
import '@vuepic/vue-datepicker/dist/main.css'
import dayjs from 'dayjs'
import { useCalendarStore } from '@/store/calendar'

export default defineComponent({
  name: 'DateElementTribunal',
  props: {
    date: { type: Date, required: true },
    day: { type: Number, required: true },
    isFullcalendar: { type: Boolean, required: false, default: false },
  },
  setup(props) {
    const calendarStore = useCalendarStore()
    const isTribunal = computed(() => {
      let color = ''
      calendarStore.eventsList?.forEach((item) => {
        if (
          dayjs(item.since).format('YYYY.MM.DD') ===
            dayjs(props.date).format('YYYY.MM.DD') ||
          dayjs(item.till).format('YYYY.MM.DD') ===
            dayjs(props.date).format('YYYY.MM.DD') ||
          (dayjs(item.since).format('YYYY.MM.DD') <
            dayjs(props.date).format('YYYY.MM.DD') &&
            dayjs(item.till).format('YYYY.MM.DD') >
              dayjs(props.date).format('YYYY.MM.DD'))
        ) {
          return item.lawsuitEventCategory.type === 'tribunal'
            ? (color = '#F03810')
            : ''
        }
      })
      return color
    })

    const chooseDay = () => {
      if (props.isFullcalendar) {
        calendarStore.changeView(
          'timeGridDay',
          dayjs(props.date).format('DD.MM.YYYY'),
        )
      }
    }

    return {
      isTribunal,
      chooseDay,
    }
  },
})
