<template>
  <form
    :class="['tariff-form', { modal: isTariffNew }]"
    @submit.prevent="submitHandler"
  >
    <fieldset class="tariff-form__fieldset">
      <span class="label-text">
        Наименование тарифа<span v-if="isTariffNew" class="required">*</span>
      </span>
      <FormElement
        v-if="isTariffNew"
        v-model:value="fields.label"
        tagName="input"
        placeholder="Введите наименование тарифа"
        :maxLength="255"
        :errorText="validationErrors?.label"
      />
      <p v-else>{{ fields.label }}</p>
    </fieldset>
    <div class="tariff-form__row" :class="{ column: !isTariffNew }">
      <fieldset class="tariff-form__fieldset">
        <span class="label-text">
          Сумма, руб<span v-if="isTariffNew" class="required">*</span>
        </span>
        <FormElement
          v-if="isTariffNew"
          v-model:value="fields.cost"
          tagName="input"
          v-maska
          data-maska-number-locale="ru"
          data-maska-number-unsigned
          :maxLength="10"
          placeholder="Введите сумму"
          :errorText="validationErrors?.cost"
        />
        <p v-else>{{ formatDecMoney(fields.cost) }}</p>
      </fieldset>
      <fieldset class="tariff-form__fieldset">
        <span class="label-text">
          Срок активности
          <span v-if="isTariffNew" class="required">*</span></span
        >
        <SelectElement
          v-if="isTariffNew"
          :value="fields.duration"
          :options="DURATION_OPTIONS"
          :errorText="validationErrors?.duration"
          objectKey="duration"
          placeholder="Выберите срок"
          :disabled="!isTariffNew"
          @dataChanged="onValueChange"
        />
        <p v-else>{{ fields.duration?.name ?? 'Не установлен' }}</p>
      </fieldset>
    </div>
    <fieldset class="tariff-form__fieldset">
      <span class="label-text">Статус</span>
      <label
        v-for="{ label, value } of STATUSES"
        :key="value"
        :class="[
          'tariff-form__money-input',
          { active: fields.status === value },
        ]"
      >
        <input
          type="radio"
          :value="value"
          @click="onValueChange('status', value)"
        />
        {{ label }}
      </label>
    </fieldset>
    <fieldset class="tariff-form__fieldset">
      <span class="label-text">Комментарий</span>
      <FormElement
        v-model:value="fields.comment"
        tagName="textarea"
        placeholder="Введите комментарий"
        :maxLength="255"
        :errorText="validationErrors?.comment"
      />
    </fieldset>
    <fieldset class="tariff-form__fieldset">
      <span class="label-text"
        >Доступные разделы<span v-if="isTariffNew" class="required"
          >*</span
        ></span
      >
      <template v-if="isTariffNew">
        <label
          v-for="{ label, value, key } of managePermissionsArray"
          :key="key"
          :class="[
            'tariff-form__checkbox',
            {
              checked: value && fields.permissionIds?.includes(value),
            },
          ]"
        >
          <input
            type="checkbox"
            :disabled="!isTariffNew"
            @click="onValueChange('permissionIds', value)"
          />
          {{ label }}
        </label>
      </template>
      <template v-else>
        <template v-if="filteredPermissions.length">
          <div v-for="{ label, key } in filteredPermissions" :key="key">
            <span class="checkmark"></span>{{ label }}
          </div>
        </template>
        <div v-else>отсутствуют</div>
      </template>
    </fieldset>
    <NotificationsComponent
      v-show="mainError"
      :text="mainError"
      size="small"
      status="error"
    />
    <button
      :class="['tariff-form__submit-btn', { new: isTariffNew }]"
      type="submit"
      :disabled="isSubmitBtnDisabled"
    >
      {{ isTariffNew ? 'Создать тариф' : 'Сохранить' }}
    </button>
  </form>
</template>

<script lang="ts" src="./TariffForm.ts"></script>

<style scoped lang="scss" src="./TariffForm.scss"></style>
