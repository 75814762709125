import { defineComponent, computed, watch } from 'vue'
import { useUXUIStore } from '@/store/uxui'
import { useMainStore } from '@/store/main'
import { menuItemsUser, menuItemsAdmin } from '@/config/asideMenuConfig'
import { useLockBodyScroll } from '@/composables/useLockBodyScroll'
import { useRoute } from 'vue-router'
import { useEventsStore } from '@/store/events'
import { useCalendarStore } from '@/store/calendar'
import dayjs from 'dayjs'

export default defineComponent({
  name: 'AsidePanel',
  setup() {
    const route = useRoute()
    const uxuiStore = useUXUIStore()
    const mainStore = useMainStore()
    const eventsStore = useEventsStore()
    const isAdmin = uxuiStore.getIsAdmin
    const menuItems = computed(() => (isAdmin ? menuItemsAdmin : menuItemsUser))
    const { enableBodyScroll, disableBodyScroll } = useLockBodyScroll()
    const isAsideCollapsed = computed(() => uxuiStore.asideCollapsed)
    const isCalendar = computed(() => route.name === 'calendar')
    const togglePanelHandler = (): void => {
      uxuiStore.switchAside()
    }
    const calendarStore = useCalendarStore()

    const selectMenuHandler = () => {
      if (mainStore.isMobile || (mainStore.isTablet && !mainStore.isNotebook)) {
        uxuiStore.switchAside()
      }
    }

    const onValueChange = (
      _: string,
      value: string | { month: number; year: number } | number,
    ) => {
      const date = dayjs(value as string)
        .utc()
        .format('DD.MM.YYYY')
      if (date !== calendarStore.currentDate) {
        calendarStore.setCurrentData(value as string)
      }
    }

    const getToday = () => {
      calendarStore.setCurrentData(dayjs().format('DD.MM.YYYY'))
    }

    watch(
      () => uxuiStore.asideCollapsed,
      (newValue: boolean) => {
        if (
          mainStore.isMobile ||
          (mainStore.isTablet && !mainStore.isNotebook && !newValue)
        ) {
          disableBodyScroll()
        }
        if (newValue) {
          enableBodyScroll()
        }
      },
    )

    return {
      togglePanelHandler,
      menuItems,
      isAsideCollapsed,
      selectMenuHandler,
      isCalendar,
      eventsStore,
      calendarStore,
      mainStore,
      onValueChange,
      getToday,
    }
  },
})
