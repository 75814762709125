<template>
  <div
    :class="[
      'navbar',
      {
        collapsed: isAsideCollapsed,
        'calendar-nav': !isAsideCollapsed,
      },
    ]"
    v-click-outside="closeSearchOnMobile"
  >
    <!-- Smart -->
    <div class="navbar__burger-wrapper">
      <button class="navbar__burger" @click="togglePanelHandler">
        <SvgIcon :icon="'menu'" />
      </button>
    </div>

    <div class="navbar__smart-info">
      <p class="navbar__smart-info-text">{{ uxuiStore.currentPage }}</p>
    </div>

    <div class="navbar__smart-wrapper">
      <div
        @click="toggleNotificationPanelHandler"
        class="navbar__notification-wrapper"
      >
        <SvgIcon icon="bell" />
        <!-- Если есть оповещения -->
        <span
          class="navbar__notification-marker"
          v-show="isNotificationListPresent"
        ></span>
      </div>
      <div
        v-if="showSearch"
        :class="['navbar__notification-wrapper', { active: isSearchOpen }]"
        @click="toggleSearchOnMobile"
      >
        <SvgIcon icon="search" />
      </div>
      <div class="navbar__notification-wrapper">
        <DropdownMenu
          :menuItems="menuItems"
          menuIcon="userSmart"
          class="navbar__menu-dropdown"
          @profileHandler="navigateToProfileHandler"
          @logoutHandler="logoutClick"
        />
      </div>
    </div>
    <!-- ====== -->

    <div
      :class="[
        'navbar__search-wrapper',
        { open: (isMobile || isTablet) && isSearchOpen },
      ]"
      v-if="showSearch"
      v-click-outside="filtersCloseHandler"
    >
      <div class="navbar__search-wrapper-header">
        <span class="navbar__search-wrapper-title">Фильтры</span>
        <SvgIcon
          v-if="isMobile || isTablet"
          class="navbar__search-wrapper-close"
          icon="x"
          @click="toggleSearchOnMobile"
        />
      </div>
      <ul v-if="filtersSelection.length" class="navbar__filters-items">
        <li
          v-for="(selection, index) of filtersSelection.slice(0, 3)"
          :key="JSON.stringify(selection)"
          class="navbar__filters-item"
        >
          <span>{{ selection.title }}: {{ selection.value }}</span>
          <button
            class="navbar__filters-item-remove"
            type="button"
            @click.stop="onRemoveFilterClick(index, selection.parameter)"
          >
            <SvgIcon :icon="'x'" />
          </button>
        </li>
      </ul>
      <form
        novalidate
        class="navbar__search-input--wrapper"
        @submit.prevent="submitHandlers(true)"
      >
        <span class="navbar__search-wrapper-title">Поиск по фразе</span>
        <div class="navbar__input-container">
          <input
            class="navbar__search-input"
            v-model="query"
            @mousedown="filtersOpenHandler"
            placeholder="Введите поисковую фразу"
          />
          <button
            v-if="filtersSelection.length || phrase"
            class="navbar__search-button"
            type="button"
            @click.prevent="resetSearchFields(true)"
          >
            <span>Сбросить фильтр</span>
            <SvgIcon icon="x" />
          </button>
          <button
            v-else
            class="navbar__search-button"
            :type="isMobile || isTablet ? 'button' : 'submit'"
          >
            <SvgIcon icon="search" />
          </button>
        </div>
        <SearchFilters
          v-show="filterContent?.filtersList && areFiltersOpen"
          :areButtonsShown="
            !['clients-table', 'adminHome', 'adminLawyers'].includes(
              String(currentRoute.name),
            )
          "
          :content="filterContent?.filtersList"
          :selectedFilters="filtersSelection"
          :valuesChange="onValuesChange"
          :resetSearchFields="resetSearchFields"
        />
        <SearchButtonsContainer
          v-if="
            ['clients-table', 'adminHome', 'adminLawyers'].includes(
              String(currentRoute.name),
            )
          "
          :class="{
            type_clients: [
              'clients-table',
              'adminHome',
              'adminLawyers',
            ].includes(String(currentRoute.name)),
          }"
          @onResetBtnClick="resetSearchFields"
        />
      </form>
    </div>

    <div class="navbar__buttons-wrapper">
      <div
        @click="toggleNotificationPanelHandler"
        class="navbar__notification-wrapper"
      >
        <SvgIcon icon="bell" />
        <!-- Если есть оповещения -->
        <span
          class="navbar__notification-marker"
          v-show="isNotificationListPresent"
        ></span>
      </div>
      <router-link
        v-if="!uxuiStore.getIsAdmin"
        to="/profile"
        class="navbar__avatar"
      >
        <SvgIcon icon="avatar" />
      </router-link>
      <div class="navbar__logout-menu--wrapper">
        <DropdownMenu
          :menuItems="menuItems"
          menuIcon="chevron-down"
          class="navbar__menu-dropdown"
          @profileHandler="navigateToProfileHandler"
          @logoutHandler="logoutClick"
        />
      </div>
    </div>
  </div>
</template>

<script lang="ts" src="./NavBar.ts"></script>
<style lang="scss" scoped src="./NavBar.scss"></style>
