import { defineStore } from 'pinia'
import { RootState, RootGetters, RootActions } from '@/store/auth/types'

import {
  getStorageItemWithExpiry,
  setStorageItemWithExpiry,
} from '@/helpers/localStorageHelpers'
import {
  AuthFormSuccessResponse,
  SMSLoginFormPayload,
  UserLogoutResponse,
} from '@/types/auth'
import { useApiCall } from '@/composables/useApiCall'
import {
  smsLoginApiCall,
  completeLoginAdmin,
  logoutApiCall,
  logoutAdminApiCall,
} from '@/api/auth'
import { applicationApiCall } from '@/api/application'
import {
  ApplicationSuccessResponse,
  ApplicationData,
} from '@/types/application'
import { DefaultError, Error } from '@/types/httpError'
import { UserData } from '@/types/user'
const authToken = getStorageItemWithExpiry<string>('authToken')
const sessionToken = getStorageItemWithExpiry<string>('sessionToken')

export const useAuthStore = defineStore<
  string,
  RootState,
  RootGetters,
  RootActions
>('auth', {
  state: () => {
    if (!authToken) {
      localStorage.clear()
    }
    return {
      token: authToken,
      tokenType: getStorageItemWithExpiry<string>(`tokenType_${authToken}`),
      user: getStorageItemWithExpiry<UserData>(`user_${authToken}`),
      sessionToken,
      error: null,
      appData: null,
    }
  },
  getters: {
    isAuth: (state: RootState) => !!state.token,
    manageParts: (state: RootState) => {
      if (state.user) {
        const manageArr = state.user.permissions.filter((item) =>
          item.includes('manage'),
        )
        return manageArr.map((item) => item.split(' ')[1])
      } else {
        return []
      }
    },
    viewPartsLength: (state: RootState) => {
      const arr = state.user?.permissions.filter((item) =>
        item.includes('view'),
      )
      if (arr) return arr.length
      else return 0
    },
  },
  actions: {
    async clearStore() {
      this.token = null
      this.user = null
      this.sessionToken = null
      localStorage.clear()
    },

    async writeData(payload: AuthFormSuccessResponse['data']) {
      const { accessToken, expiresIn, user, tokenType, sessionToken } = payload
      this.$patch({
        token: accessToken,
        tokenType,
        user,
        sessionToken,
      })
      setStorageItemWithExpiry('authToken', accessToken, expiresIn * 1000)
      setStorageItemWithExpiry(`user_${accessToken}`, user)
      setStorageItemWithExpiry(`tokenType_${accessToken}`, tokenType)
      if (sessionToken) setStorageItemWithExpiry(`sessionToken`, sessionToken)
    },

    async userSmsLogin(payload: SMSLoginFormPayload) {
      const {
        data: authData,
        executeApiCall: loginAction,
        error: loginError,
      } = useApiCall<AuthFormSuccessResponse, Error, SMSLoginFormPayload>(
        smsLoginApiCall,
        true,
        payload,
      )
      this.error = null
      try {
        await loginAction()
        if (authData.value) {
          await this.writeData(authData.value.data)
        }
      } catch {
        if (typeof loginError.value?.data.error === 'object') {
          this.error = loginError.value.data.error
        } else if (loginError.value?.data.error) {
          this.error = { error: loginError.value.data.error }
        }
      }
    },
    async adminSmsLogin(payload) {
      const {
        data: authData,
        executeApiCall: loginAction,
        error: loginError,
      } = useApiCall<AuthFormSuccessResponse, Error, SMSLoginFormPayload>(
        completeLoginAdmin,
        true,
        payload,
      )
      this.error = null

      try {
        await loginAction()
        if (authData.value) {
          await this.writeData(authData.value.data)
        }
      } catch {
        if (typeof loginError.value?.data.error === 'object') {
          this.error = loginError.value.data.error
        } else if (loginError.value?.data.error) {
          this.error = { error: loginError.value.data.error }
        }
      }
    },
    async logoutRequest() {
      const {
        data: logoutData,
        executeApiCall: logoutAction,
        error: logoutError,
      } = useApiCall<UserLogoutResponse, DefaultError, SMSLoginFormPayload>(
        logoutApiCall,
        true,
      )
      this.error = null
      try {
        await logoutAction()
        if (logoutData?.value?.success) {
          await this.clearStore()
        }
      } catch {
        if (logoutError.value?.data.error.error) {
          this.error = { error: logoutError.value?.data.error.error }
        }
      }
    },
    async logoutAdminRequest() {
      const {
        data: logoutData,
        executeApiCall: logoutAction,
        error: logoutError,
      } = useApiCall<UserLogoutResponse, DefaultError, SMSLoginFormPayload>(
        logoutAdminApiCall,
        true,
      )
      this.error = null
      try {
        await logoutAction()
        if (logoutData?.value?.success) {
          await this.clearStore()
        }
      } catch {
        if (logoutError.value?.data.error.error) {
          this.error = { error: logoutError.value?.data.error.error }
        }
      }
    },
    async getAppData() {
      const {
        data: applicationData,
        error: applicationError,
        executeApiCall: applicationAction,
      } = useApiCall<ApplicationSuccessResponse, DefaultError>(
        applicationApiCall,
        true,
      )

      try {
        await applicationAction()
        if (applicationData.value) {
          this.appData = applicationData.value
            .data as unknown as ApplicationData
        }
      } catch (error) {
        if (applicationError.value?.data.error.error) {
          this.error = { error: applicationError.value?.data.error.error }
        }
      }
    },
  },
})
