import { defineStore } from 'pinia'
import { computed, ref } from 'vue'
import { useUXUIStore } from '@/store/uxui'
import { useLawsuitStore } from '@/store/lawsuite'
import { useAuthorityStore } from '@/store/authorities'
import { useEventsStore } from '@/store/events'
import { useTasksStore } from '@/store/tasks'
import { useNotesStore } from '@/store/notes'
import { useFinanceStore } from '@/store/finance'

export const useSavePopupStore = defineStore('savePopup', () => {
  const getStorageItemWithExpiry = (key: string) => {
    const item = localStorage.getItem(key)
    if (item) {
      return JSON.parse(item)
    }
    return {}
  }
  const uxUiStore = useUXUIStore()
  const lawsuitStore = useLawsuitStore()
  const authorityStore = useAuthorityStore()
  const eventsStore = useEventsStore()
  const tasksStore = useTasksStore()
  const notesStore = useNotesStore()
  const financeStore = useFinanceStore()
  const saveData = ref<{ [key: string]: any }>(
    getStorageItemWithExpiry('saveData'),
  )
  const isSave = ref(false)
  const isLoad = ref(false)
  const setSaveData = (value: any, key: string, isValid: boolean) => {
    if (!value.id) {
      saveData.value[key] = {
        data: { ...value },
        isValid: isValid,
      }
    }
  }

  const writeData = () => {
    localStorage.setItem('saveData', JSON.stringify(saveData.value))
  }

  const deleteSaveData = (key: string) => {
    delete saveData.value[key]
    writeData()
  }

  const checkCreate = computed(() => {
    switch (uxUiStore.modalName?.modalName) {
      case 'EditLawsuit':
        return !lawsuitStore.selectedLawsuitData?.id
      case 'EditReview':
        return !authorityStore.authoritiesData?.id
      case 'EventForm':
        return !eventsStore.currentEvent
      case 'TaskForm':
        return !tasksStore.selectedTask?.id
      case 'EditNote':
        return !notesStore.selectedNote
      case 'TransactionForm':
        return !financeStore.selectedTransaction
      default:
        return true
    }
  })

  return {
    saveData,
    isSave,
    setSaveData,
    deleteSaveData,
    writeData,
    isLoad,
    checkCreate,
  }
})
