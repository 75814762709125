import { computed, defineComponent, ref } from 'vue'
import { useUXUIStore } from '@/store/uxui'
import { modalsTitleNames } from '@/config/commonModalsConfig'
import { useEventsStore } from '@/store/events'
import { useSavePopupStore } from '@/store/savePopup'

export default defineComponent({
  name: 'ModalHeader',
  props: {
    index: {
      type: Number,
      required: false,
    },
  },
  setup() {
    const uxuiStore = useUXUIStore()
    const eventsStore = useEventsStore()
    const savePopupStore = useSavePopupStore()
    const isSave = ref(false)
    const toggleSave = () => {
      if (
        savePopupStore.saveData[uxuiStore.modalName.modalName] &&
        savePopupStore.checkCreate
      ) {
        isSave.value = true
      } else {
        handleClose()
      }
    }
    const handleClose = (): void => {
      uxuiStore.setModalName('')
      isSave.value = false
      if (!uxuiStore.eventVisible) {
        eventsStore.currentEvent = null
      }
    }

    const isValid = computed(
      () => savePopupStore.saveData[uxuiStore.modalName.modalName].data,
    )

    const setSaveData = () => {
      if (isValid.value) {
        savePopupStore.isSave = true
      }
      // else {
      //   isSave.value = false
      // }
      isSave.value = false
    }

    const closeSaveData = () => {
      savePopupStore.deleteSaveData(uxuiStore.modalName.modalName)
      handleClose()
    }

    const getLoadData = () => {
      savePopupStore.isSave = true
      savePopupStore.isLoad = false
    }

    const closeLoadData = () => {
      savePopupStore.deleteSaveData(uxuiStore.modalName.modalName)
      savePopupStore.isLoad = false
    }

    return {
      handleClose,
      modalsTitleNames,
      uxuiStore,
      isSave,
      setSaveData,
      closeSaveData,
      toggleSave,
      getLoadData,
      closeLoadData,
      savePopupStore,
      isValid,
    }
  },
})
