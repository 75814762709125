<template>
  <template v-if="isTribunal">
    <span
      @click="chooseDay"
      :class="[
        {
          withColoritem: isTribunal && !isFullcalendar,
          withColor: isTribunal && isFullcalendar,
        },
      ]"
      :style="{ background: isTribunal, color: 'white' }"
      >{{ day }}</span
    >
  </template>
  <template v-else>
    <span @click="chooseDay">{{ day }}</span>
  </template>
</template>

<script lang="ts" src="./DateElementTribunal.ts"></script>
<style lang="scss" src="./DateElementTribunal.scss"></style>
