<template>
  <div :class="['table-head', { '--settings': isSettings }]">
    <TableHeadItem
      v-for="item in items"
      :openedItem="openedItem"
      @openItem="openItem"
      @changeSort="onChangeSort"
      :item="item"
      :key="JSON.stringify(item)"
    />
  </div>
</template>

<script lang="ts" src="./TableHead.ts"></script>
<style lang="scss" scoped src="./TableHead.scss"></style>
